import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  uri_video: string = 'https://www.youtube.com/embed/YLXXM8O5tlQ';
  
  constructor() { }

  ngOnInit() {
  }
  
  stopPlayer(){
    
    let listaFrames = document.getElementsByTagName("iframe");
    let iframe      = listaFrames[0].contentWindow;

    iframe.postMessage({"event":"command","func":"pauseVideo","args":""}, '*');

  }

}
