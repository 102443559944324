import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';

import { Subject } from "rxjs/Subject";

import { FacebookService, LoginResponse, LoginOptions, UIResponse, UIParams, FBVideoComponent } from 'ngx-facebook';

import { globalVariables } from '../_globals/variables';

@Injectable()
export class AuthenticateService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: Http, public fb: FacebookService) {
    fb.init({
      appId: globalVariables['FACEBOOK_APP_ID'],
      version: globalVariables['FACEBOOK_API_VERSION']
    });
    }

  login(user) {
    return this.http.post(this.apiEndpoint + '/user/login', user);
  }

  clearCredentials() {
    sessionStorage.removeItem('current-user');
    localStorage.removeItem('current-user');
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    return this.http.get(this.apiEndpoint + '/user/logout');
  }

  isLogged() {
    let current_user = null;
    if ( sessionStorage.getItem("current-user") != null){
      current_user = JSON.parse( sessionStorage.getItem("current-user") );
    }else if( localStorage.getItem("current-user") != null ){
      current_user = JSON.parse( localStorage.getItem("current-user") );
    }
    return current_user;
  }

  isAdmin() {
    const user = this.isLogged();
    if (user && user.plans.length > 0) {
      const group = user.plans[user.plans.length - 1].category;
      return group == 'Administrator';
    } else {
      return null;
    }
  }

  /* STATUS LOGGED ESCUTA - BEGIN */
  private usersession = new Subject<any>();
  usersession$ = this.usersession.asObservable();
  
  setUserSession(usersession: any){
    this.usersession.next(usersession);
  }
  /* STATUS LOGGED ESCUTA - END */


  loginWithFacebook() {
    const loginOptions: LoginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'public_profile,email'
    };

    return this.fb.login(loginOptions)
      .then((res: LoginResponse) => {
          return this.fb.api('/me?fields=email,first_name, last_name');
      })
      .catch(err => console.log(err));
  }

  processLoginWithFacebook(data){
    return this.http.post(this.apiEndpoint + '/user/loginWithFacebook', data);
  }


  signupWithFacebook() {
    const loginOptions: LoginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'public_profile,email'
    };

    //Faz o login com facebook e retorna a promisse para preencher o formulário com os dados
    return this.fb.login(loginOptions)
      .then((res: LoginResponse) => {
        return this.fb.api('/me?fields=email,first_name, last_name');
      })
      .catch(err => console.log(err));
  }

  getAuthToken() : string {
    if ( sessionStorage.getItem("token") != null) {
      return JSON.parse(sessionStorage.getItem("token"));
    }
    if ( localStorage.getItem("token") != null ) {
      return JSON.parse(localStorage.getItem("token"));
    }
    return null;
  }

}