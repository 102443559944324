import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertMonthYear'
})
export class ConvertMonthYear implements PipeTransform {

  month: number = -1;
  str_month: string = '';

  transform(value: any, args?: any): any {
    if (!value) return value;
    
    this.month = value.getMonth();

    switch(this.month){
      case 0:
        this.str_month = 'Jan';
      break;
      case 1:
        this.str_month = 'Feb';
      break;
      case 2:
        this.str_month = 'Mar';
      break;
      case 3:
        this.str_month = 'Apr';
      break;
      case 4:
        this.str_month = 'May';
      break;
      case 5:
        this.str_month = 'Jun';
      break;
      case 6:
        this.str_month = 'Jul';
      break;
      case 7:
        this.str_month = 'Aug';
      break;
      case 8:
        this.str_month = 'Sep';
      break;
      case 9:
        this.str_month = 'Oct';
      break;
      case 10:
        this.str_month = 'Nov';
      break;
      case 11:
        this.str_month = 'Dec';
      break;
      default:
        this.str_month = '';
    }
    return this.str_month + '/' + value.getFullYear();
  }

}
