import { Component, OnInit, Input } from '@angular/core';
import { globalVariables } from '../../_globals/variables';
import { CollectionService } from '../../_services/collection.service';
import { UserService } from "app/_services/user.service";
declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() data_profile: any;

  GLOBAL: any = globalVariables; 
  user: any;
  userSession: any;
  userLogged: any;
  value_btn: any;
  storage: any = {};
  btnFollowDisabled: boolean = false;
  isFollowing: boolean = false;
  requestToFollow: boolean = false;
  actionButtonFollowers: string = 'FOLLOWERS';
  actionButtonFollowing: string = 'FOLLOWING';
  userModalData: any = {
    user: {},
    type: null,
    values: null
  };

  constructor(private collectionService: CollectionService, private userService: UserService) {
  }

  ngOnInit() {
    this.user = this.data_profile.user;
    this.user.created_at = new Date(this.user.created_at);
    
    this.getUserCollections(this.user._id);
    
    this.value_btn = {
      followers: this.data_profile.user.followers ? this.data_profile.user.followers.length : 0,
      following: this.data_profile.user.following ? this.data_profile.user.following.length : 0
    }
    
    this.storage.session = JSON.parse(sessionStorage.getItem('current-user'));
    this.storage.local = JSON.parse(localStorage.getItem('current-user'));
    if ( this.storage.session == null && this.storage.local == null ) {
      this.btnFollowDisabled = true;
    } else {
      if ( this.storage.session != null ) {
        this.userSession = this.storage.session;
      } else {
        this.userSession = this.storage.local;
      }

      if (this.userSession._id == this.user._id) {
        this.btnFollowDisabled = true;
        this.isFollowing = true;
      } else {
        this.checkIsFollowing();
      }
    }
  }

  checkIsFollowing() {
    if (this.user.followers && this.user.followers.includes(this.userSession._id)) {
      this.isFollowing = true;
      this.requestToFollow = false;
      this.btnFollowDisabled = false;
    } else if (this.user.follower_requests && this.user.follower_requests.includes(this.userSession._id)) {
      this.isFollowing = false;
      this.requestToFollow = true;
      this.btnFollowDisabled = true;
    } else {
      this.isFollowing = false;
      this.requestToFollow = false;
      this.btnFollowDisabled = false;
    }
  }

  getUserCollections(user_id){
    const query = {
      user: user_id
    }
    this.collectionService.processSearch(query, 1, 100).subscribe((res: any) => {
      this.user.collections = res.docs;
    });
  }

  follow() {
    if (this.isFollowing) {
      const data = {
        '_id': this.user._id,
        'mod': {
          'followers' : this.userSession._id
        }
      }
      this.userService.unfollowUser(data).subscribe((res: any) => {
        if (res.nModified) {
          const index = this.user.followers.indexOf(this.userSession._id);
          this.user.followers.splice(index, 1);
          this.value_btn.followers--;
          this.checkIsFollowing();
        }
      });  
    } else {
      if (this.user.private == undefined || this.user.private == true) {
        const data = {
          '_id': this.user._id,
          'to': this.user.email,
          'from_name': `${this.userSession.firstname} ${this.userSession.lastname}`,
          'mod': {
            'follower_requests' : this.userSession._id
          }
        }
        this.userService.requestFollowUser(data).subscribe((res: any) => {
          if (res.success) {
            if (this.user.follower_requests) {
              this.user.follower_requests.push(this.userSession._id);
            } else {
              this.user['follower_requests'] = this.userSession._id;
            }
            this.checkIsFollowing();
          }
        });
      } else {
        const data = {
          '_id': this.user._id,
          'type': 'direct',
          'to': this.user.email,
          'from_name': `${this.userSession.firstname} ${this.userSession.lastname}`,
          'mod': {
            'followers' : this.userSession._id
          }
        }
        this.userService.followUser(data).subscribe((res: any) => {
          if (res.success) {
            this.user.followers.push(this.userSession._id);
            this.value_btn.followers++;
            this.checkIsFollowing();
          }
        });
      }
    }
    
  }

  onOpened(type: string) {
    $('#listUsersModal').modal('show');
    if (type === 'FOLLOWERS') {
      const data = {
        "query": {
          "_id": this.data_profile.user._id
        },
        "fields": {
          "firstname": 1,
          "lastname": 1
        }
      }
      this.userService.getFollowers(data).subscribe((res: any) => {
        const response = {
          'user': {
            _id: this.data_profile.user._id,
            firstname: this.data_profile.user.firstname,
            lastname: this.data_profile.user.lastname,
            photo: this.data_profile.user.photo,
          },
          'type': type,
          'values' : res[0].followers
        }
        this.userModalData = response;
      });
    } else if (type === 'FOLLOWING') {
      this.userService.getFollowing(this.data_profile.user._id).subscribe((res: any) => {
        const response = {
          'user': {
            _id: this.data_profile.user._id,
            firstname: this.data_profile.user.firstname,
            lastname: this.data_profile.user.lastname,
            photo: this.data_profile.user.photo,
          },
          'type': type,
          'values' : res
        }
        this.userModalData = response;
      });
    }
  }

  viewCollection(isPrivate) {
    if (isPrivate == undefined || isPrivate == true) {
      if (this.isFollowing) {
        return true;
      } else {
        return false;
      }
    } else {
      /* Public */
      return true;
    }
  }

}
