import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

@Injectable()
export class CheckoutService {

  constructor(private http: Http) { }


  placeOrder(order){ 
    
    let headers: Headers = new Headers();
    headers.append("Authorization","Basic VEdCVFg3V0FYOThaU1U3SkZVWEZROVRJVlhYVFFEQkM6R1pJMFVMR1RLMk9TTEZBUDJURVM2TVRaV1A3TTQ3RFJEQk1VSUk3VA==");
    
    // let options = new RequestOptions({ headers: headers });

    return this.http.post( "https://sandbox.moip.com.br/v2/orders", order, {headers: headers})
    .map((res:Response) => res.json());
  }

  processPayment(order_id, obj){

    let headers: Headers = new Headers();
    headers.append("Authorization","Basic VEdCVFg3V0FYOThaU1U3SkZVWEZROVRJVlhYVFFEQkM6R1pJMFVMR1RLMk9TTEZBUDJURVM2TVRaV1A3TTQ3RFJEQk1VSUk3VA==");

    return this.http.post( "https://sandbox.moip.com.br/v2/orders/"+ order_id + "/payments", obj, {headers: headers})
    .map((res:Response) => res.json());

  }

}
