import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dividing-line',
  templateUrl: './dividing-line.component.html',
  styleUrls: ['./dividing-line.component.scss']
})
export class DividingLineComponent {

  @Input() title: string  = 'TEXT';
  @Input() uppercase: boolean  = false;

}
