import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collections-tabs',
  templateUrl: './collections-tabs.component.html',
  styleUrls: ['./collections-tabs.component.scss']
})
export class CollectionsTabsComponent {

  @Input() collections: any;

  ngOnInit() {
    this.collections = [];
  }

}
