import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-icon',
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.scss']
})
export class BtnIconComponent {

  @Input() icon: string  = 'USABILITY_MLs-PUBLISHED';
  @Input() value: string = 'Button value';
  @Input() lg: boolean = false;
  @Input() btnDisabled: boolean = false;
  @Input() data: any = null;
  @Input() message: string  = null;
  @Output() opened = new EventEmitter<string>();

  action() {
    if (this.data) {
      this.opened.emit(this.data);
    }
  }

}
