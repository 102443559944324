import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { globalVariables } from '../_globals/variables';

@Injectable()
export class UserService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: HttpClient) { }

  insertUser(user){
    return this.http.post(this.apiEndpoint + '/user', user);
  }

  followUser(data){
    return this.http.post(this.apiEndpoint + '/user/follow', data);
  }

  requestFollowUser(data){
    return this.http.post(this.apiEndpoint + '/user/requestfollow', data);
  }

  unfollowUser(data){
    return this.http.post(this.apiEndpoint + '/user/unfollow', data);
  }

  updateUser(user){
    return this.http.put(this.apiEndpoint + '/user/' + user._id, user);
  }

  updatePrivacy(data){
    return this.http.put(this.apiEndpoint + '/user/update/privacy/' + data._id, data);
  }

  updatePassword(user){
    return this.http.put(this.apiEndpoint + '/user/update/password/' + user._id, user);
  }
  
  listPlans(query){
    return this.http.post(this.apiEndpoint + '/plan/findPost', query);
  }

  resetPasswordStep1(obj){
    return this.http.post(this.apiEndpoint + '/user/resetPasswordStep1', obj);
  }

  checkCoupon(coupon){
    return this.http.get(this.apiEndpoint + '/coupon/valid/' + coupon);
  }

  emailConfirmation(_id){
    return this.http.get(this.apiEndpoint + '/user/confirm/' + _id);
  }

  checkStatus(id, updated_at){
    return this.http.post(this.apiEndpoint + '/user/checkStatus', {'id': id, 'updated_at': updated_at});
  }

  emailReSendConfirmation(_id){
    return this.http.get(this.apiEndpoint + '/user/sendConfirmationEmail/' + _id);
  }

  getUser(_id) {
    return this.http.get(`${this.apiEndpoint}/user/${_id}`);
  }

  getFollowers(data) {
    return this.http.post(this.apiEndpoint + '/user/followers', data);
  }

  getFollowing(_id) {
    return this.http.get(`${this.apiEndpoint}/user/following/${_id}`);
  }

}
