import { Injectable } from '@angular/core';

import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';

import { globalVariables } from '../_globals/variables';

@Injectable()
export class MessageService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(private http: Http) {
  }

   sendMessage(m){  
    return this.http.post( this.apiEndpoint + '/message',m)
    .map((res:Response) => res.json());
  }

}
