import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserModel } from '../_models/user.model';
import { UserService } from "app/_services/user.service";
import { dataCountries } from '../_data/countries';
import { dataNationalities } from '../_data/nationalities';
import { AuthenticateService } from "app/_services/authenticate.service";
import { HelpService } from 'app/_services/help.service';

declare var $: any;
declare var md5: any;
declare var paypal: any;

function confirmPassword(input: FormControl) {
  if ( !input['root']['controls'] ) {
    return null;
  }
  const exactMatch = input['root']['controls']['password']['value'] === input.value;
  return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
  selector: 'app-modal-sign-up',
  templateUrl: './modal-sign-up.component.html',
  styleUrls: ['./modal-sign-up.component.scss']
})
export class ModalSignUpComponent implements OnInit {

  debug = false;

  currentStep: number = 1;
  submitted: boolean = false;
  emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  countries: Array<any>;
  plans: any;
  plan_selected: any;
  name_plan_selected: string = '';
  error_message: string = '';
  error_name: string = '';
  auth: any = {};
  page_view: boolean = false;
  months: Array<any>;
  years: Array<any>;
  error_message_coupon: string = '';
  view_coupon: any = {};

  professions = ['NON ACADEMIC PROFESSIONAL', 'STUDENT', 'TEACHER'];

  nationalities: Array<any>;

  msgObj = { visible: false, message: "", class: "alert-success" }

  user: UserModel;
  alert = { visible: false, msg: ""}

  paypalProcessing = false;
  paypalProgressMessage = false;

  first_name =  new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  last_name = new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  email = new FormControl('', [
    Validators.required, 
    Validators.pattern(this.emailRegex), 
    Validators.maxLength(150)
  ]);

  password = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20)
  ]);

  repassword = new FormControl('', [
    Validators.required, 
    confirmPassword
  ]);

  accepted = new FormControl(false, [
    Validators.requiredTrue
  ]);


  country = new FormControl('', [
    Validators.required
  ]);

  nationality = new FormControl('', [
    Validators.required
  ]);

  address = new FormControl('', [
    Validators.maxLength(150)
  ]);

  phoneNumber = new FormControl('', [
    Validators.maxLength(20)
  ]);

  profession = new FormControl('', [
    Validators.maxLength(50),
    Validators.required
  ]);

  receiveNews = new FormControl(true, []);

  formGroupStep1: FormGroup = this.builder.group({
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email,
    password: this.password,
    repassword: this.repassword,
    accepted: this.accepted
  });

  formGroupStep2: FormGroup = this.builder.group({
    nationality: this.nationality,
    country: this.country,
    address: this.address,
    phoneNumber: this.phoneNumber,
    profession: this.profession,
    receiveNews: this.receiveNews
  });

  formForgotPass: FormGroup = this.builder.group({
    email: this.email
  });

  constructor(private builder: FormBuilder, 
              private userService: UserService, 
              private authenticateService: AuthenticateService,
              private helpService: HelpService
            ) {


        if(location.hash == "#debug") this.debug = true;

  
    
    this.countries = dataCountries['VALUES'];
    this.nationalities = dataNationalities['VALUES'];
    this.user = new UserModel();

    this.months = [
      {desc: 'Jan', num: 1},
      {desc: 'Feb', num: 2},
      {desc: 'Mar', num: 3},
      {desc: 'Apr', num: 4},
      {desc: 'May', num: 5},
      {desc: 'Jun', num: 6},
      {desc: 'Jul', num: 7},
      {desc: 'Aug', num: 8},
      {desc: 'Sep', num: 9},
      {desc: 'Oct', num: 10},
      {desc: 'Nov', num: 11},
      {desc: 'Dec', num: 12}
    ];

  }

  ngOnInit() {


    $(document).ready(function(){
      $('[data-toggle="tooltip"]').tooltip();
    });
    if ( location.pathname.indexOf('view-material') > 0 || location.pathname.indexOf('ml') > 0) this.page_view = true;

    if ( (location.pathname.indexOf('view-material') >= 0 || location.pathname.indexOf('/ml') >= 0) && sessionStorage.getItem("current-user") == null && localStorage.getItem("current-user") == null ){
      this.page_view = true;
    }

    //Verifica se o usuario já está logado e pega os dados para realizar o pagamento.
    let current_user = null;
    if(sessionStorage.getItem("current-user") != null){
      current_user = JSON.parse(sessionStorage.getItem("current-user"));
    }else if (localStorage.getItem("current-user") != null){
      current_user = JSON.parse(localStorage.getItem("current-user"));
    }

    if(current_user != null){

      this.user.setId(current_user._id);
      this.user.setFirstName(current_user.firstname);
      this.user.setLastName(current_user.lastname);
      this.user.setEmail(current_user.email);
      this.user.setCountry(current_user.country_of_residence);
      this.user.setProfession(current_user.profession);
      this.user.setNationality(current_user.nationality);
      delete this.user.auth;
    }
  }

  nextStep(id){
    this.currentStep++;
  }

  closeModal(id){
    this.currentStep = 1;
    this.formGroupStep1.reset();
    $("#"+id).modal('hide');
  }

  openModal(id){
    $("#"+id).modal('show');
  }

  onSubmitStep1(): void {
    this.submitted = true;

    if ( this.formGroupStep1.valid ) {

      this.user.setFirstName(this.formGroupStep1.controls['first_name'].value);
      this.user.setLastName(this.formGroupStep1.controls['last_name'].value);
      this.user.setEmail(this.formGroupStep1.controls['email'].value);
      this.user.setPassword({ password: this.formGroupStep1.controls['password'].value });
      this.user.setSocialMedia({"linkedin" : "", "instagram" : "", "twitter" : "", "facebook" : ""})


      this.userService.insertUser(this.user).subscribe((res: any) => {
        if ( res.success == true ){
          this.user.setId(res.userId);
          this.formGroupStep1.reset();

          /* USER AUTHENTICATE - BEGIN */
          this.auth.email = this.user.getEmail();
          const type_auth = this.user.getPassword();
          this.auth.password = type_auth.local.password;

          this.authenticateService.login(this.auth).subscribe((res: any) => {
            const result = JSON.parse(res._body);
            this.user = new UserModel(result.user);
            const token = result.token;

            delete this.user.auth;
            delete this.auth;

            if (typeof(Storage) !== 'undefined') {
              sessionStorage.setItem('current-user', JSON.stringify(this.user));
              sessionStorage.setItem('token', JSON.stringify(token));
              this.authenticateService.setUserSession(this.user);
            }
            this.msgObj.visible = false;
            this.currentStep = 2;
            this.submitted = false;
          });
          /* USER AUTHENTICATE - END */
        }else{
          this.error_name    = res.name;
          this.error_message = res.message;
          this.submitted = false;

          this.msgObj.message = res.message;
          this.msgObj.visible = true;
          setTimeout( ()=> this.msgObj.visible = false, 8000);
        }
      });
      
    }else{
      this.submitted = false;

      this.msgObj.message = "Please fill all form fields and accept the terms of service."
      this.msgObj.visible = true;
      setTimeout( ()=> this.msgObj.visible = false, 20000);
    }
  }

  onSubmitStep2(): void {
    this.submitted = true;
    if ( this.formGroupStep2.valid ) {
      this.user.setCountry(this.formGroupStep2.controls['country'].value);
      this.user.setProfession(this.formGroupStep2.controls['profession'].value);
      this.user.setReceiveNews(this.formGroupStep2.controls['receiveNews'].value);
      this.user.setNationality(this.formGroupStep2.controls['nationality'].value);

      let obj = {
        _id: this.user.getId(),
        email: this.user.getEmail(),
        country_of_residence: this.user.getCountry(),
        profession: this.user.getProfession(),
        receiveNews: this.user.getReceiveNews(),
        nationality: this.user.getNationality()
      }

      this.userService.updateUser(obj).subscribe((res: any) => {
        if ( res.ok ){
          sessionStorage.setItem('current-user', JSON.stringify(this.user));
          this.authenticateService.setUserSession(this.user);
          this.formGroupStep2.reset();
          this.currentStep = 3;
          this.submitted = false;
        }else{
          this.submitted = false;
          this.submitted = false;
          this.msgObj.message = "Un unexpected error has occurred, please try again later."
          this.msgObj.visible = true;
          setTimeout( ()=> this.msgObj.visible = false, 20000);
        }
      });

    }else{
      this.submitted = false;
      this.msgObj.message = "Please fill all form fields."
      this.msgObj.visible = true;
      setTimeout( ()=> this.msgObj.visible = false, 20000);
    }
  }

  goResetPass(){
    this.formForgotPass.controls['email'] = this.formGroupStep1.controls['email'];
    this.currentStep = 4;
  }

  onSubmitForgotPass(){
    this.alert.visible = false;
    let now = new Date();
    let hash = this.formForgotPass.controls['email'].value + ";"+now.getTime();
    hash = btoa(hash);
    let obj = {
      email: this.formForgotPass.controls['email'].value,
      hash: hash
    }
    this.userService.resetPasswordStep1(obj).subscribe((res: any) => {
      if(res.success){
        this.formGroupStep1.reset();
        this.formForgotPass.reset();
        this.currentStep = 5;
      }else{
        this.alert.msg = res.msg;
        this.alert.visible = true;
      }
    });
  }
  

  signupFacebook(){
    //Pega os dados retornados pelo facebook
    this.authenticateService.signupWithFacebook().then((res: any) => {
      this.formGroupStep1.controls['first_name'].setValue(res.first_name);
      this.formGroupStep1.controls['last_name'].setValue(res.last_name);
      this.formGroupStep1.controls['email'].setValue(res.email);
      // this.user.setFirstName(this.formGroupStep1.controls['first_name'].value);
      // this.user.setLastName(this.formGroupStep1.controls['last_name'].value);
      // this.user.setEmail(this.formGroupStep1.controls['email'].value);
      this.user.auth.facebook.email = res.email;
      this.user.auth.facebook.id = res.id;
      if(!this.formGroupStep1.valid){
        this.msgObj.message = "Please accept the terms of service, fill password for your account at Materials Life and click 'Next'.";
        this.msgObj.visible = true;
      }
    })
    .catch(err => console.log(err));
  }

}