import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserModel } from '../_models/user.model';
import { UserService } from "app/_services/user.service";
import { dataCountries } from '../_data/countries';
import { dataNationalities } from '../_data/nationalities';
import { globalVariables } from '../_globals/variables';
import { AuthenticateService } from 'app/_services/authenticate.service';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ng2-img-cropper';
import { CollectionService } from '../_services/collection.service';
import { stringify } from '@angular/core/src/util';
import { AlertifyService } from '../_services/alertify.service';
declare var $: any;
declare var md5: any;

function confirmPassword(input: FormControl) {
  if ( !input['root']['controls'] ) {
    return null;
  }
  const exactMatch = input['root']['controls']['newpassword']['value'] === input.value;
  return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  submitted = {
    formProfile: false,
    formPassword: false
  };
  emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  countries: Array<any>;
  nationalities: Array<any>;
  storage: any = {};
  user: any = {};
  GLOBAL: any = globalVariables; 
  PLAN: any = {};
  professions = ['NON ACADEMIC PROFESSIONAL', 'STUDENT', 'TEACHER'];
  alert: any = {
    status: {
      success: false,
      error: false
    },
    message: {
      success: '',
      error: ''
    },
    to: ''
  };
  logged: boolean = true;
  /* Crop image */
  data1:any;
  cropperSettings1:CropperSettings;
  croppedWidth:number;
  croppedHeight:number;

  processing: boolean = false;

  data_to_profile: any = {};

  @ViewChild('cropper', undefined) cropper:ImageCropperComponent;

  first_name =  new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  last_name = new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  nationality = new FormControl('', [
    Validators.required
  ]);

  country_of_residence = new FormControl('', [
    Validators.required
  ]);

  graduation = new FormControl('', [
    Validators.maxLength(80)
  ]);

  profession = new FormControl('', [
    Validators.required
  ]);

  email = new FormControl('', [
    Validators.required, 
    Validators.pattern(this.emailRegex), 
    Validators.maxLength(150)
  ]);

  about = new FormControl('', [
    Validators.maxLength(500)
  ]);

  facebook = new FormControl('', [
    Validators.maxLength(400)
  ]);

  twitter = new FormControl('', [
    Validators.maxLength(400)
  ]);

  instagram = new FormControl('', [
    Validators.maxLength(400)
  ]);

  linkedin = new FormControl('', [
    Validators.maxLength(300)
  ]);

  birth = new FormControl('', []);

  photo = new FormControl('', []);

  formEditProfile: FormGroup = this.builder.group({
    first_name: this.first_name,
    last_name: this.last_name,
    birth: this.birth,
    nationality: this.nationality,
    country_of_residence: this.country_of_residence,
    graduation: this.graduation,
    profession: this.profession,
    email: this.email,
    about: this.about,
    facebook: this.facebook,
    twitter: this.twitter,
    instagram: this.instagram,
    linkedin: this.linkedin,
    photo: this.photo
  });

  password = new FormControl('', [
    Validators.required
  ]);

  newpassword = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20)
  ]);

  repassword = new FormControl('', [
    Validators.required, 
    confirmPassword
  ]);

  formChangePass: FormGroup = this.builder.group({
    password: this.password,
    newpassword: this.newpassword,
    repassword: this.repassword
  });

  constructor(
    private builder: FormBuilder,
    private userService: UserService,
    private authenticateService: AuthenticateService,
    private alertify: AlertifyService,
    private collectionService: CollectionService
  ){
    this.cropperSettings1 = new CropperSettings();
    this.cropperSettings1.noFileInput = true;
    this.cropperSettings1.width = 200;
    this.cropperSettings1.height = 200;
    this.cropperSettings1.keepAspect = false;

    this.cropperSettings1.croppedWidth = 200;
    this.cropperSettings1.croppedHeight = 200;
    
    this.cropperSettings1.canvasWidth = 500;
    this.cropperSettings1.canvasHeight = 300;

    this.cropperSettings1.minWidth = 100;
    this.cropperSettings1.minHeight = 100;

    this.cropperSettings1.fileType = 'image/jpeg';

    this.cropperSettings1.rounded = true;
    this.cropperSettings1.minWithRelativeToResolution = false;
    
    this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings1.cropperClass = 'crop-canvas';

    this.data1 = {};

    authenticateService.usersession$.subscribe( usersession => {
      if ( usersession == null ){
        this.logged = false;
      }
      // else{
      //   this.user = usersession;
      //   this.logged = true;
      //   this.loadForm(this.user);
      // }
    });

  }

  cropped(bounds:Bounds) {
    this.croppedHeight = bounds.bottom-bounds.top;
    this.croppedWidth  = bounds.right-bounds.left;
  }
  
  fileChangeListener($event) {
    var image:any = new Image();
    var file:File = $event.target.files[0];
    var myReader:FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent:any) {
        image.src = loadEvent.target.result;
        that.cropper.setImage(image);

    };
    myReader.readAsDataURL(file);
  }

  ngOnInit() {
    this.countries = dataCountries['VALUES'];
    this.nationalities = dataNationalities['VALUES'];
    this.storage.session = JSON.parse(sessionStorage.getItem('current-user'));
    this.storage.local = JSON.parse(localStorage.getItem('current-user'));
    if ( this.storage.session == null && this.storage.local == null ) {
      this.logged = false;
      this.checkLogin();
    }else{
      this.getIdUserSession((userId) => {
        this.userService.getUser(userId).subscribe((user: any) => {
          var userLogged = user;
          this.user = userLogged[0];
          this.loadForm(this.user);
          this.getUserCollections(this.user._id);
        });
      });
    }
  }

  getIdUserSession(callback) {
    if ( this.storage.session != null ) {
      return callback(this.storage.session._id);
    }else{
      return callback(this.storage.local._id);
    }
  }

  getUserCollections(user_id){
    const query = {
      user: user_id
    }
    this.collectionService.processSearch(query, 1, 100).subscribe((res: any) => {
      this.user.collections = res.docs;
    });
  }

  loadForm(user){
    this.PLAN.ACTIVE = false;
    if ( user.plans && user.plans.length > 0 ) {
      this.PLAN.ACTIVE = true;
      this.PLAN.ICON = user.plans[user.plans.length -1].icon;
      this.PLAN.NAME = user.plans[user.plans.length -1].name;
      this.PLAN.STARTEDON = user.plans[user.plans.length -1].startedon ? new Date(user.plans[user.plans.length -1].startedon) : '';
      this.PLAN.ENDSON = user.plans[user.plans.length -1].endson ? new Date(user.plans[user.plans.length -1].endson) : '';
    }
    this.formEditProfile.controls['first_name'].setValue(user.firstname);
    this.formEditProfile.controls['last_name'].setValue(user.lastname);
    this.formEditProfile.controls['email'].setValue(user.email);
    this.formEditProfile.controls['graduation'].setValue(user.graduation);
    this.formEditProfile.controls['profession'].setValue(user.profession);
    this.formEditProfile.controls['birth'].setValue(user.birth ? user.birth.substr(0, 10) : '');
    this.formEditProfile.controls['nationality'].setValue(this.user.nationality);
    this.formEditProfile.controls['country_of_residence'].setValue(user.country_of_residence);
    this.formEditProfile.controls['linkedin'].setValue(user.socialmedia ? user.socialmedia.linkedin : '');
    this.formEditProfile.controls['twitter'].setValue(user.socialmedia ? user.socialmedia.twitter : '');
    this.formEditProfile.controls['facebook'].setValue(user.socialmedia ? user.socialmedia.facebook : '');
    this.formEditProfile.controls['instagram'].setValue(user.socialmedia ? user.socialmedia.instagram : '');
    this.formEditProfile.controls['about'].setValue(user.about);
    this.formEditProfile.controls['photo'].setValue(user.photo);

  }

  checkLogin(){
    if ( sessionStorage.getItem("current-user") == null && localStorage.getItem("current-user") == null ) {
      this.openModalLogin();
    }
  }

  openModalLogin() {
    $('#LoginModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#LoginModal').on('shown.bs.modal', function (e) {
      $(".modal-backdrop.in").css({ opacity: 0.9 });
    });
  }

  onSubmitSave(){
    this.processing = true;
    this.submitted.formProfile = true;
    if ( this.formEditProfile.valid ) {

      let data = {
        _id: this.user._id,
        firstname: this.formEditProfile.controls['first_name'].value,
        lastname: this.formEditProfile.controls['last_name'].value,
        email: this.formEditProfile.controls['email'].value,
        graduation: this.formEditProfile.controls['graduation'].value,
        profession: this.formEditProfile.controls['profession'].value,
        birth: this.formEditProfile.controls['birth'].value,
        nationality: this.formEditProfile.controls['nationality'].value,
        country_of_residence: this.formEditProfile.controls['country_of_residence'].value,
        socialmedia: {
          linkedin: this.formEditProfile.controls['linkedin'].value,
          twitter: this.formEditProfile.controls['twitter'].value,
          facebook: this.formEditProfile.controls['facebook'].value,
          instagram: this.formEditProfile.controls['instagram'].value
        },
        about: this.formEditProfile.controls['about'].value,
        photo: this.formEditProfile.controls['photo'].value
      };

      if ( !data.photo ) delete data.photo;
      this.userService.updateUser(data).subscribe((res: any) => {
        this.processing = false;
        if ( res.ok ){
          this.user.firstname = data.firstname;
          this.user.lastname = data.lastname;
          this.user.email = data.email;
          this.user.graduation = data.graduation;
          this.user.profession = data.profession;
          this.user.birth = data.birth;
          this.user.nationality = data.nationality;
          this.user.country_of_residence = data.country_of_residence;
          this.user.socialmedia.linkedin = data.socialmedia.linkedin;
          this.user.socialmedia.twitter = data.socialmedia.twitter;
          this.user.socialmedia.facebook = data.socialmedia.facebook;
          this.user.socialmedia.instagram = data.socialmedia.instagram;
          this.user.about = data.about;
          this.user.photo = data.photo;
          
          if ( this.storage.session ){
            sessionStorage.setItem('current-user', JSON.stringify(this.user));
          }else{
            localStorage.setItem('current-user', JSON.stringify(this.user));
          }
          this.authenticateService.setUserSession(this.user); //atualiza a header
          this.submitted.formProfile = false;
          this.setAlert('success', 'update', 'Profile successfully changed');
        }else{
          this.setAlert('error', 'update', 'Error changing profile');
          this.submitted.formProfile = false;
        }
      });
    }else{
      this.setAlert('error', 'update', 'Fill the required fields');
      this.submitted.formProfile = false;
    }
  }

  onSubmitPassword(){
    this.submitted.formPassword = true;
    if ( this.formChangePass.valid ) {
      const auth = { email: this.user.email, password: this.formChangePass.controls['password'].value }
          , data = { _id: this.user._id, password: this.formChangePass.controls['newpassword'].value }
          ;

      this.authenticateService.login(auth).subscribe((res: any) => {
        this.userService.updatePassword(data).subscribe((res: any) => {
          if ( res.ok ){
            this.formChangePass.reset();
            this.submitted.formPassword = false;
            this.setAlert('success', 'password', 'Password successfully changed');
          }else{
            this.setAlert('error', 'password', 'Error changing password');
            this.submitted.formPassword = false;
          }
        });
      },
      error => { 
        this.setAlert('error', 'password', 'Current password invalid');
        this.submitted.formPassword = false;
      });
      
    }else{
      this.setAlert('error', 'password', 'Fill the required fields');
      this.submitted.formPassword = false;
    }

  }

  setAlert(type = 'clear', to = '', message = '', delay = 6000 ){
    if ( type == 'success' ){
      this.alert = {
        status: {
          success: true,
          error: false
        },
        message: {
          success: message,
          error: ''
        },
        to: to
      }
    }else if ( type == 'error' ){
      this.alert = {
        status: {
          success: false,
          error: true
        },
        message: {
          success: '',
          error: message
        },
        to: to
      }
    }else{
      this.alert = {
        status: {
          success: false,
          error: false
        },
        message: {
          success: '',
          error: ''
        },
        to: ''
      }
    }

    setTimeout( () => { this.setAlert(); }, delay);

  }

  confirmCancelAccount(){
    $('#confirm').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  cancelAccount(){
    const data = {
      _id: this.user._id,
      active: false
    };

    this.userService.updateUser(data).subscribe((res: any) => {
      if ( res.ok ){
        this.logout();
      }
    });
  }

  logout() {
    this.authenticateService.clearCredentials().subscribe( res => {
      sessionStorage.removeItem('current-user');
      localStorage.removeItem('current-user');
      this.logged = false;
      location.href = '/';
    });
  }

  modalSelectImage(){
    $('#editimage').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  selectImage() {
    this.user.photo = this.data1.image;
    this.formEditProfile.controls['photo'].setValue(this.data1.image);
    this.cropper.reset();
    $('#editimage').modal('hide');
  }

  isPrivate(value) {
    return value ? true : false;
  }
  setPrivacy(id, value) {
    const data = {
      _id: id,
      private: value
    };
    this.userService.updatePrivacy(data).subscribe((res: any) => {
      if ( res.ok ){
        this.user.private = value;        
        if ( this.storage.session != null ) {
          this.storage.session.private = value;
          sessionStorage.setItem('current-user', JSON.stringify(this.storage.session));
        }else{
          this.storage.local.private = value;
          localStorage.setItem('current-user', JSON.stringify(this.storage.local));
        }
        this.setAlert('success', 'update', 'Profile successfully changed');
      }else{
        this.setAlert('error', 'update', 'Error changing profile');
      }
    });
  }

  updateUserSession() {
    if ( this.storage.session){
      sessionStorage.setItem('current-user', JSON.stringify(this.user));
    }else{
      localStorage.setItem('current-user', JSON.stringify(this.user));
    }
  }

  /* Accept request to follow */
  accept(index, user) {
    const msg = `Allow <b>${user.firstname} ${user.lastname}</b> to follow you and your collections?`;
    this.alertify.confirm('Attention', msg,
      () => {
        const dataUpdateToFollowers = {
          '_id': this.user._id,
          'type': 'accepted',
          'to': user.email,
          'from_name': `${this.user.firstname} ${this.user.lastname}`,
          'mod': {
            'followers' : user._id
          }
        }
        this.userService.followUser(dataUpdateToFollowers).subscribe((res: any) => {
          const resultToUpdateFollowers = res;
          if (resultToUpdateFollowers.success) {
            this.user.followers.push(user._id);
            const dataUpdateToRequestFollowes = {
              '_id': this.user._id,
              'mod': {
                'follower_requests' : user._id
              }
            }
            this.userService.unfollowUser(dataUpdateToRequestFollowes).subscribe((res: any) => {
              if (res.nModified) {
                this.user.follower_requests.splice(index, 1);
                this.updateUserSession();
              }
            });
          }
        });
      },
      () => {}
    );
  }

  /* Decline request to follow */
  decline(index, user) {
    const msg = `Decline the request of <b>${user.firstname} ${user.lastname}</b>?`;
    this.alertify.confirm('Attention', msg,
      () => {
        const dataUpdateToRequestFollowes = {
          '_id': this.user._id,
          'mod': {
            'follower_requests' : user._id
          }
        }
        this.userService.unfollowUser(dataUpdateToRequestFollowes).subscribe((res: any) => {
          if (res.nModified) {
            this.user.follower_requests.splice(index, 1);
            this.updateUserSession();
          }
        });
      },
      () => {}
    );
  }

}
