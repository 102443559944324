import { Component, OnInit } from '@angular/core';
import { HelpService } from 'app/_services/help.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  alphabet: any = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  search_by: string;
  filter_by: string = 'A';
  faqs: any;
  arr_all: any;
  arr_how_it_works: any;
  arr_sales_signatures: any;
  arr_technical: any;
  glossaries_all: any;
  glossaries: any;
  searched: boolean = false;

  constructor(private title: Title, private helpService: HelpService) { }

  ngOnInit() {
    this.title.setTitle('Help');

    // List FAQ'S
    this.helpService.listFaqs().subscribe((res: any) => {
      this.faqs = res;
      this.arr_all = [];
      this.arr_how_it_works     = this.faqs.filter( obj => { return (obj.category == "How it works") });
      this.arr_sales_signatures = this.faqs.filter( obj => { return (obj.category == "Sales / Signatures") });
      this.arr_technical        = this.faqs.filter( obj => { return (obj.category == "Technical") });
    });

    // List Glossaries
    this.helpService.listGlossaries().subscribe((res: any) => {
      this.glossaries_all = res;
      this.glossaries = this.glossaries_all.filter ( obj => { return (obj.keyword.substring(0,1) == this.filter_by) });
    });
  }

  filterGlossary(letter) {
    this.filter_by = letter;
    this.glossaries = this.glossaries_all.filter ( obj => { return (obj.keyword.substring(0,1) == this.filter_by) });
  }

  filterFaq() {
    let faqs = [];
    this.searched = true;
    if ( this.search_by ){
      faqs = this.faqs.filter( obj => { return obj.question.toLowerCase().match(this.search_by.toLowerCase()) || obj.answer.toLowerCase().match(this.search_by.toLowerCase()); });
    }else{
      faqs = this.faqs;
    }
    this.arr_all = faqs;
    this.arr_how_it_works     = [];
    this.arr_sales_signatures = [];
    this.arr_technical        = [];
  }

}
