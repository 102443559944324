import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ng2-img-cropper';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { TokenInterceptor } from './_interceptors/token.interceptor';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SearchResultsComponent } from './search-results/search-results.component';

import { routing } from "app/app.routing";
import { UiSwitchModule } from '../../node_modules/angular2-ui-switch/src';
import { HomeService } from "app/_services/home.service";
import { FilterStructureComponent } from './filter-structure/filter-structure.component';
import { FooterComponent } from './footer/footer.component';
import { ViewMaterialComponent } from './view-material/view-material.component';
import { ViewMaterialService } from "app/_services/view-material.service";
import { SearchResultsService } from "app/_services/search-results.service";
import { ModalContactUsComponent } from './modal-contact-us/modal-contact-us.component';
import { ModalSignUpComponent } from './modal-sign-up/modal-sign-up.component';
import { ModalChooseYourPlanComponent } from './modal-choose-your-plan/modal-choose-your-plan.component';
import { UserService } from "app/_services/user.service";
import { MessageService } from "app/_services/message.service";
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalLoginComponent } from './modal-login/modal-login.component';
import { SrFilterPipe } from './_pipes/sr-filter.pipe';
import { AuthenticateService } from "app/_services/authenticate.service";
import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HelpComponent } from './help/help.component';
import { HelpService } from 'app/_services/help.service';
import { HelpersService } from 'app/_services/helpers.service';
import { ContentsComponent } from './contents/contents.component';
import { ContentsService } from 'app/_services/contents.service';
import { FacebookModule } from 'ngx-facebook';
import { ProfileComponent } from './profile/profile.component';
import { ConvertMonthYear } from './_pipes/date/convert-month-to-string.pipe';
import { LoaderComponent } from './loader/loader.component';
import { CheckoutService } from './_services/checkout.service'
import { PaymentService } from './_services/payment.service';
import { AuthGuard } from "app/_guards/auth-guard";
import { BannerService } from './_services/banner.service';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { NgxJsonLdModule } from 'ngx-json-ld';
import { AlertifyService } from './_services/alertify.service';
import { ConvertDollarToRealService } from './_services/convert-dollar-to-real.service';

import { BtnIconComponent } from './_components/buttons/btn-icon/btn-icon.component';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { ThumbCircleComponent } from './_components/thumb-circle/thumb-circle.component';
import { DividingLineComponent } from './_components/dividing-line/dividing-line.component';
import { BtnSocialIconComponent } from './_components/buttons/btn-social-icon/btn-social-icon.component';
import { CollectionsTabsComponent } from './_components/collections-tabs/collections-tabs.component';
import { ThumbCollectionComponent } from './_components/thumb-collection/thumb-collection.component';
import { CollectionComponent } from './collection/collection.component';
import { CollectionService } from './_services/collection.service';
import { SpinnerComponent } from './_components/spinner/spinner.component';
import { ViewCollectionComponent } from './view-collection/view-collection.component';
import { BannerComponent } from './_components/banner/banner.component';
import { ModalAddImageToCollectionComponent } from './modal-add-image-to-collection/modal-add-image-to-collection.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ModalListUsersComponent } from './modal-list-users/modal-list-users.component';
import { PrivateUserMessageComponent } from './_components/private-user-message/private-user-message.component';
import { CryptoService } from './_services/crypto.service';
import { ModalContributionComponent } from './modal-contribution/modal-contribution.component';
import { DeviceDetectorModule } from "ngx-device-detector";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    SearchResultsComponent,
    FilterStructureComponent,
    FooterComponent,
    ViewMaterialComponent,
    ModalContactUsComponent,
    ModalSignUpComponent,
    ModalChooseYourPlanComponent,
    HowItWorksComponent,
    ModalAlertComponent,
    ModalLoginComponent,
    SrFilterPipe,
    ModalProfileComponent,
    ResetPasswordComponent,
    HelpComponent,
    ContentsComponent,
    ProfileComponent,
    ConvertMonthYear,
    LoaderComponent,
    EmailConfirmationComponent,
    BtnIconComponent,
    UserProfileComponent,
    ThumbCircleComponent,
    DividingLineComponent,
    BtnSocialIconComponent,
    CollectionsTabsComponent,
    ThumbCollectionComponent,
    CollectionComponent,
    SpinnerComponent,
    ViewCollectionComponent,
    BannerComponent,
    ModalAddImageToCollectionComponent,
    MyAccountComponent,
    PageNotFoundComponent,
    ModalListUsersComponent,
    PrivateUserMessageComponent,
    ModalContributionComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    routing,
    UiSwitchModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgxJsonLdModule,
    FacebookModule.forRoot(),
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    HomeService,
    ViewMaterialService,
    SearchResultsService,
    UserService,
    MessageService,
    AuthenticateService,
    HelpService,
    HelpersService,
    ContentsService,
    CheckoutService,
    PaymentService,
    AuthGuard,
    CollectionService,
    BannerService,
    AlertifyService,
    CryptoService,
    ConvertDollarToRealService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  entryComponents: [FilterStructureComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
