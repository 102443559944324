import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CollectionService } from '../_services/collection.service';
import { AuthenticateService } from '../_services/authenticate.service';
import alertify from 'alertifyjs';
declare var $: any;

@Component({
  selector: 'app-modal-add-image-to-collection',
  templateUrl: './modal-add-image-to-collection.component.html',
  styleUrls: ['./modal-add-image-to-collection.component.scss']
})
export class ModalAddImageToCollectionComponent implements OnInit {

  @Input() imageSelected: any;

  collections: any;
  copyCollections: any;
  submitted: boolean = false;
  collectionSelected: any = null;
  viewFormNewCollection: boolean = false;
  filter_collections: string = null;
  user: any = null;

  title =  new FormControl('', [
    Validators.required, 
    Validators.maxLength(300)
  ]);

  about =  new FormControl('', [
    Validators.maxLength(500)
  ]);

  formGroup: FormGroup = this.builder.group({
    title: this.title,
    about: this.about,
  });

  constructor(private builder: FormBuilder,
              private collectionService: CollectionService,
              private authService: AuthenticateService) {

  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    this.collectionSelected = null;
    this.filter_collections = null;
    this.user = this.authService.isLogged();
    if (this.user != null) this.getUserCollections(this.user._id);
  }

  getUserCollections(user_id) {
    this.collectionService.processSearch( {user: user_id}, null, 100).subscribe((res: any) => {
      this.collections = res.docs;
      this.copyCollections = res.docs;
    })
  }

  setCollection(item) {
    this.collections.map((coll) => {
      if (coll._id === item._id) {
        coll.selected = true;
        this.collectionSelected = item;
      } else {
        coll.selected = false;
      }
    });
  }

  addImageToCollection() {
    const currentImage = this.collectionSelected.images.find((img) => {
      return img._id === this.imageSelected._id;
    });
    if (currentImage === undefined) {
      this.collectionService.addImage(this.collectionSelected._id, this.imageSelected._id).subscribe((res: any) => {
        if (res.nModified > 0) {
          this.collectionSelected.images.push({_id: this.imageSelected._id});
          alertify.success('image added successfully');
          $("#AddImageToCollectionModal").modal('hide');
        } else {
          alertify.error('error while adding');
        }
      });
    } else {
      alertify.error('image already exists in this collection');
    }
  }

  filterCollections() {
    if (!this.collections) {
      this.collections = [];
    } else if (!this.filter_collections) {
      this.collections = this.copyCollections;
    } else {
      this.filter_collections = this.filter_collections.toLowerCase();
      this.collections = this.copyCollections.filter(item => {
        return item.title.toLowerCase().includes(this.filter_collections);
      });
    }
  }

  showHideForm() {
    this.viewFormNewCollection = !this.viewFormNewCollection;
    if (!this.viewFormNewCollection) this.formGroup.reset();
  }

  createCollection() {
    this.submitted = true;
    if (this.user != null && this.formGroup.valid) {
      const data = {
        user: this.user._id,
        title: this.formGroup.controls['title'].value,
        slug: this.formGroup.controls['title'].value,
        about: this.formGroup.controls['about'].value
      };
      this.collectionService.createCollection(data).subscribe((res: any) => {
        if (res.success) {
          this.filter_collections = null;
          this.copyCollections.push(res.data);
          this.collections = this.copyCollections;
          this.submitted = false;
          this.showHideForm();
          alertify.success('Collection created successfully');
        } else {
          alertify.error('Error saving, please try again');    
        }
      });
    } else {
      alertify.error('Error saving, please try again');
      this.submitted = false;
    }
  }

}
