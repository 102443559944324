import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from "app/_services/message.service";

@Component({
  selector: 'app-modal-contact-us',
  templateUrl: './modal-contact-us.component.html',
  styleUrls: ['./modal-contact-us.component.scss']
})
export class ModalContactUsComponent implements OnInit {

  error  = false;
  showSuccess = false;

  emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';

  first_name =  new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  last_name = new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  email = new FormControl('', [
    Validators.required, 
    Validators.pattern(this.emailRegex), 
    Validators.maxLength(150)
  ]);

  message =  new FormControl('', [
    Validators.required, 
    Validators.minLength(5), 
    Validators.maxLength(300)
  ]);

  contactForm: FormGroup = this.builder.group({
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email,
    message: this.message
  });

  constructor(private builder: FormBuilder, private messageService: MessageService) { }

  ngOnInit() {
  }


  sendMessage(){
    if ( this.contactForm.valid ) {
     
      let message = {
        firstName: this.contactForm.controls['first_name'].value,
        lastName:  this.contactForm.controls['last_name'].value,
        email:     this.contactForm.controls['email'].value,
        msg:       this.contactForm.controls['message'].value
      }

      console.log(message);

      this.messageService.sendMessage(message).subscribe( res => {
        if(res.success){
          this.showSuccess = true;
        }else 
            this.error = true;
      }, error =>{
        this.error = true;
      })
      
    }

  }

}
