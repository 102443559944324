import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { globalVariables } from '../_globals/variables';

@Injectable()
export class ContentsService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: HttpClient) { }
  
  listContents(){
    return this.http.get(this.apiEndpoint + '/ml/findMlsPublished');
  }

}
