import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
    this.checkLogin();
  }

  showModal(){
    setTimeout( ()=>{
      $("#AlertModal").modal('show');
    },5000);
  }

  closeModal(){
    $("#AlertModal").modal('hide');
  }

  checkLogin() {
    let current_user = null;
    if ( sessionStorage.getItem("current-user") != null){
      current_user = JSON.parse( sessionStorage.getItem("current-user") );
    }else if( localStorage.getItem("current-user") != null ){
      current_user = JSON.parse( localStorage.getItem("current-user") );
    }
    if ( current_user == null || current_user.plans.length == 0 ){
      this.showModal();
    }else{
      let current_plan = current_user.plans[current_user.plans.length -1]
        , endson = new Date(current_plan.endson)
        ;
        if ( endson < new Date() ) this.showModal();
    }
  }

}
