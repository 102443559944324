export let globalVariables: object = {
    /* LOCALHOST */
    // URI_API_PRODUCTION: 'http://localhost:8081/api',
    // URI_ICONS_PLANS: location.protocol + '//dev.icons.materials.life/icons-plans/',

    /* HOMOLOG */
    // URI_API_PRODUCTION: 'http://materials.life:8080/api',

    /* PRODUCTION */
    URI_API_PRODUCTION: 'https://materials.life:8443/api',
    // URI_API_PRODUCTION: 'http://materials.life:8081/api',
    // URI_API_PRODUCTION: 'https://api.materials.life',

    URI_ICONS_PLANS: 'https://mlspaces.nyc3.digitaloceanspaces.com/imgs/icons-plans/',
    URI_IMAGES: 'https://mlspaces.nyc3.digitaloceanspaces.com/imgs/',
    URI_BANNERS: 'https://mlspaces.nyc3.digitaloceanspaces.com/imgs/banners/',

    /*URI_ICONS_PLANS: location.protocol + '//imgs.materials.life/icons-plans/',
    URI_IMAGES: 'https://imgs.materials.life/',
    URI_BANNERS: 'https://imgs.materials.life/banners/',*/

    /* FACEBOOK CONFIG */
    FACEBOOK_APP_ID: '146334752661966',
    FACEBOOK_API_VERSION: 'v2.10',

    /* SOCIAL MEDIA LINKS */
    PREFIX_LINK_SOCIAL_MEDIA: {
        FACEBOOK: 'https://www.facebook.com/',
        TWITTER: 'https://twitter.com/',
        LINKEDIN: 'https://www.linkedin.com/in/',
        INSTAGRAM: 'https://www.instagram.com/'
    }
};