import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { globalVariables } from '../_globals/variables';

@Injectable()
export class BannerService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: HttpClient) { }
  
  getBanners(query){
    return this.http.post(this.apiEndpoint + '/banner/find', query);
  }

  saveStatistics(obj) {
    return this.http.post(this.apiEndpoint + '/banner-view', obj);
  }

}
