import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-thumb-circle',
  templateUrl: './thumb-circle.component.html',
  styleUrls: ['./thumb-circle.component.scss']
})
export class ThumbCircleComponent implements OnInit {

  @Input() url: string  = './assets/images/profile.png';
  @Input() alt: string  = '';

  constructor() { }

  ngOnInit() {
  }

}
