import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from "app/_services/user.service";
import { AuthenticateService } from "app/_services/authenticate.service";

function confirmPassword(input: FormControl) {
  if ( !input['root']['controls'] ) {
    return null;
  }
  const exactMatch = input['root']['controls']['password']['value'] === input.value;
  return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  linkDate: Date = null;
  submitted: boolean = false;
  user: any = {};
  storage: string = '';
  alert: any = {
    status: {
      success: false,
      error: false
    },
    message: {
      success: '',
      error: ''
    }
  };

  linkExpired = false;

  password = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20)
  ]);

  repassword = new FormControl('', [
    Validators.required, 
    confirmPassword
  ]);

  formEditPassword: FormGroup = this.builder.group({
    password: this.password,
    repassword: this.repassword
  });

  constructor(private route: ActivatedRoute, private builder: FormBuilder, private userService: UserService, private authenticateService: AuthenticateService) { }
  
  ngOnInit() {


    this.route.params.subscribe( params => {

      this.user._id = params.userId;
      let hashDecoded =  atob(params.hash).split(";");

      this.user.email = hashDecoded[0];
      this.linkDate = new Date( parseInt( hashDecoded[1] ) );

      let now = new Date();

      
      let diff = now.getTime() - this.linkDate.getTime(); //pega a diferença das datas em milisegundos
          diff = diff/1000/60/60; //transforma milisegundos em horas

      
      if(diff > 24){
        this.linkExpired = true;
        this.setAlert('error', 'This link has expired. Please make a new request for password reset .');
        this.submitted = false;
      }

    });
  }

  onSubmitPassword(){
    this.submitted = true;
    if ( this.formEditPassword.valid ) {
      this.user.password = this.formEditPassword.controls['password'].value;
      const data = { _id: this.user._id, password: this.user.password };      
        this.userService.updatePassword(data).subscribe((res: any) => {
          if ( res.ok ){
            this.formEditPassword.reset();
            this.submitted = false;
            this.setAlert('success', 'Your password has been changed successfully.');
            /* Authenticate - begin */
            const auth = { email: this.user.email, password: this.user.password };
            this.authenticateService.login(auth).subscribe((res: any) => {
              setTimeout( () => {
                this.user = {};
                sessionStorage.setItem('current-user', JSON.stringify(res));
                this.authenticateService.setUserSession(res);
                location.href = '/';
              }, 5000 );
            });
            /* Authenticate - end */
          }else{
            this.setAlert('error', 'Error reseting password');
            this.submitted = false;
          }
        });
    }else{
      this.setAlert('error', 'Fill the required fields');
      this.submitted = false;
    }

  }


  setAlert(type = 'clear', message = '' ){
    if ( type == 'success' ){
      this.alert = {
        status: {
          success: true,
          error: false
        },
        message: {
          success: message,
          error: ''
        }
      }
    }else if ( type == 'error' ){
      this.alert = {
        status: {
          success: false,
          error: true
        },
        message: {
          success: '',
          error: message
        }
      }
    }else{
      this.alert = {
        status: {
          success: false,
          error: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    }
  }

}
