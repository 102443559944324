import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { globalVariables } from '../_globals/variables';
import { AuthenticateService } from '../_services/authenticate.service';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, finalize, switchMap, take, filter } from 'rxjs/operators';
declare var $: any;

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    globals: any = globalVariables;

    constructor(private authService: AuthenticateService) { }

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ) : Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        const requestUrl: Array<any> = request.url.split('/');
        const apiUrl: Array<any> = this.globals.URI_API_PRODUCTION.split('/');
        const token = this.authService.getAuthToken();

        /* verifica se a requisição é para a api da aplicação */
        if (token && (requestUrl[2] === apiUrl[2])) {
            return next.handle(this.addTokenToRequest(request, token))
                .pipe(
                    catchError(err => {
                        if (err instanceof HttpErrorResponse) {
                            switch ((<HttpErrorResponse>err).status) {
                                case 401: {
                                    this.handle401Error(request, next);
                                }
                                case 400: {
                                    this.authService.clearCredentials().subscribe( res => {
                                        location.href = '/';
                                    });
                                }
                            }
                        } else {
                            return err;
                        }
                        return err;
                    })
                );  
        
        } else {
            return next.handle(request);
        }
 
    }
 
    private addTokenToRequest(request: HttpRequest<any>, token: string) : HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
    }
 
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        this.authService.clearCredentials();
        
        $('#LoginModal').modal({
            backdrop: 'static',
            keyboard: false
        });
        $('#LoginModal').on('shown.bs.modal', function (e) {
            $(".modal-backdrop.in").css({ opacity: 0.9 });
        });

    }

}
