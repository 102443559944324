import { Injectable } from '@angular/core';

@Injectable()
export class HelpersService {

  constructor() { }

  parseImageSecureUrl(imageUrl){
    return imageUrl.replace('http://67.205.137.39/imgs/', 'https://mlspaces.nyc3.digitaloceanspaces.com/imgs/');
  }

}
