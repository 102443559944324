import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { globalVariables } from '../_globals/variables';

@Injectable()
export class CollectionService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: HttpClient) { }

  processSearch(query, page: number = null, limit = null){

    (page == null ? 1 : page);
    let obj = {
      query: query,
      page: page,
      limit: limit
    }
    return this.http.post(this.apiEndpoint + '/user-collection/search', obj);
  }

  addImage(idCollection, idImage) {
    return this.http.put(this.apiEndpoint + `/user-collection/addImage/${idCollection}`, {_id: idImage});
  }

  createCollection(data) {
    return this.http.post(this.apiEndpoint + '/user-collection/create', data);
  }

  followCollection(data){
    return this.http.post(this.apiEndpoint + '/user-collection/follow', data);
  }

  unfollowCollection(data){
    return this.http.post(this.apiEndpoint + '/user-collection/unfollow', data);
  }

  getCollection(query) {
    return this.http.post(this.apiEndpoint + '/user-collection/slug', query);
  }

  updateCollection(id, mod) {
    return this.http.put(this.apiEndpoint + `/user-collection/${id}`, mod);
  }

  updateCountViewes(id, mod) {
    return this.http.put(this.apiEndpoint + `/user-collection/countviewes/${id}`, mod);
  }
  countviewes

  removeImageFromCollection(data){
    return this.http.post(this.apiEndpoint + '/user-collection/removeImageFromCollection', data);
  }

  getCollectionsMostRecent() {
    return this.http.get(this.apiEndpoint + '/user-collection/mostrecent');
  }

}
