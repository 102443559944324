import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FilterService } from "app/_services/filter.service";
import { Subscription } from "rxjs/Subscription";

@Component({
  selector: 'app-filter-structure',
  templateUrl: './filter-structure.component.html',
  styleUrls: ['./filter-structure.component.scss']
})
export class FilterStructureComponent implements OnInit {

  subscription: Subscription;

  visible = true;

  _obj : any = {title: "", label:"", icon: "", items: [] }

  // @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set obj(obj: any) {
    
    switch(obj.title) {

      case 'MATERIAL':
        obj.icon = "icon icon-MLs_TT-MATERIAL";
        break;
      case 'PRODUCT':
        obj.icon = "icon icon-MLs_TT-PRODUCTS";
        break;
      case 'APPLICATION':
        obj.icon = "icon icon-MLs_TT-APPLICATIONS";
        break;
      case 'CONDITION':
        obj.icon = "icon icon-MLs_TT-CONDITION";
        break;
      case 'CAPTURE.METHOD':
        obj.icon = "icon icon-MLs_TT-CAPTURE";
        break;
      case 'DETAIL.FOCUS':
        obj.icon = "icon icon-MLs_TT-DETAILS";
        break;

    }

    this._obj = obj;
  }

  constructor(private filterService: FilterService) {

    this.filterService.setVisible(true);

   }

  ngOnInit() {
  }

  handleClick(obj){

    this.resetSelection();

    if(obj.hasOwnProperty("active")){
      obj.active = !obj.active;
    }else obj.active = true;
    
    this.filterService.setId(obj);
  }

  uiSwitchChange(event){
    this.filterService.setVisible(event);
  }

  resetSelection(){
    for (let i = 0; i < this._obj.items.length; i++) {
      this._obj.items[i].active = false;
      
    }
  }

  dblClickHandler(){
    //Importante aguardar um pouco, pois se clicar no ícone de um filho, tem que esperar a service incluir o filho no filter
    setTimeout( ()=>this.filterService.setProcessSearch(true),1000);
    
  }

}
