import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { globalVariables } from '../_globals/variables';

@Injectable()
export class HelpService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(public http: HttpClient) { }

  listFaqs(){
    let fields = '{"priority": 1, "question": 1}';
    return this.http.get(this.apiEndpoint + '/faq/find/orderby/'+fields);
  }

  listGlossaries(){
    let fields = '{"keyword": 1}';
    return this.http.get(this.apiEndpoint + '/glossary/find/orderby/'+fields);
  }

  getDollarConversion(){
    return this.http.get(this.apiEndpoint + '/plan/dollar_conversion');
  }

}
