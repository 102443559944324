import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';

import { globalVariables } from '../_globals/variables';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class PaymentService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(private http: Http) { }

  getUsertoken(){  
    return this.http.get( this.apiEndpoint + "/payment/bt_client_token")
    .map((res:Response) => res.json());
  }

  processPayment(data){  
    return this.http.post( this.apiEndpoint + "/payment/btProcessCheckout", data)
    .map((res:Response) => res.json());
  }

  infoContribuition(data) {
    return this.http.post(this.apiEndpoint + "/payment/infoContribuition", data).map((res: Response) => res.json());
  }

}
