import { Injectable } from '@angular/core';

import { Http, Response } from '@angular/http';

import 'rxjs/add/operator/map';

import { globalVariables } from '../_globals/variables';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class HomeService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(private http: Http) {
  }

   /* SHARED variable*/
   private randomImage = new Subject<any>();
   randomImage$ = this.randomImage.asObservable();
   setrandomImage(imagePath: string) {
     this.randomImage.next(imagePath);
   }
   /* SET BANNER STATUS - END */

   getCategory(category){  
    return this.http.get( this.apiEndpoint+"/"+category + "/findFromCache")
    .map((res:Response) => res.json());
  }

  getFeaturedImages(){
    return this.http.get( this.apiEndpoint + '/ml/featuredImages')
    .map((res:Response) => res.json());
  }

  getMostRecentImages(){
    return this.http.get( this.apiEndpoint + '/ml/mostRecent')
    .map((res:Response) => res.json());
  }

  getRandomImage(){
    return this.http.get( this.apiEndpoint + '/ml/randomImage')
    .map((res:Response) => res.json());
  }

}
