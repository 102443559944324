import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'srFilter'
})
export class SrFilterPipe implements PipeTransform {

  transform(values: any, filter: any): any {

    if(filter.length > 0){
      return values.filter( val=>{
        return val.mainView.title.toLowerCase().includes(filter.toLowerCase())
      });
    }else
      return values;
    
  }

}
