import { ModuleWithProviders } from "@angular/core/core";
import { Routes, RouterModule }   from '@angular/router';
import { HomeComponent } from "app/home/home.component";
import { SearchResultsComponent } from "app/search-results/search-results.component";
import { ViewMaterialComponent } from "app/view-material/view-material.component";
import { ResetPasswordComponent } from "app/reset-password/reset-password.component";
import { HelpComponent } from "app/help/help.component";
import { ContentsComponent } from "app/contents/contents.component";
import { ProfileComponent } from "app/profile/profile.component";
import { AuthGuard } from "app/_guards/auth-guard";
import { EmailConfirmationComponent } from 'app/email-confirmation/email-confirmation.component';
import { CollectionComponent } from "app/collection/collection.component";
import { ViewCollectionComponent } from "./view-collection/view-collection.component";
import { MyAccountComponent } from "./my-account/my-account.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";


const APP_ROUTES: Routes = [

	{ path: '', component:	HomeComponent},
	{ path: 'help', component:	HelpComponent},
	{ path: 'contents', component: ContentsComponent },
	{ path: 'collections', component: CollectionComponent },
	{ path: 'search', component: SearchResultsComponent },
	{ path: 'my-account', component: MyAccountComponent },
	{ path: 'profile/:id', component: ProfileComponent },
	{ path: 'view-material/:id', component: ViewMaterialComponent },
	{ path: 'ml/:slug', component: ViewMaterialComponent },
	{ path: 'ml/:slug/:image_index', component: ViewMaterialComponent },
	{ path: 'email-confirmation/:userId', component: EmailConfirmationComponent },
	{ path: 'reset-password/:userId/:hash', component: ResetPasswordComponent },
	{ path: 'collections/:slug', component: ViewCollectionComponent },
	{ path: '**', component: PageNotFoundComponent },
	
]

export const routing: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES);