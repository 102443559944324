import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { globalVariables } from 'app/_globals/variables';

@Injectable()
export class SearchResultsService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  constructor(private http: Http) { }

  processSearch(obj){
    return this.http.post( this.apiEndpoint + '/ml/search', obj)
    .map((res:Response) => res.json());
  }

}
