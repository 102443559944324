import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { globalVariables } from 'app/_globals/variables';
import { CollectionService } from '../_services/collection.service';
import { AlertifyService } from '../_services/alertify.service';
import { AuthenticateService } from 'app/_services/authenticate.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-collection',
  templateUrl: './view-collection.component.html',
  styleUrls: ['./view-collection.component.scss']
})
export class ViewCollectionComponent implements OnInit {

  uriImages = globalVariables['URI_IMAGES'];

  actionButtonFollowing: any = null;

  collection: any = {
    about: '',
    created_at: '',
    followers: [],
    images: [],
    slug: '',
    title: '',
    user: {},
    views: 0,
  };
  mainImage: string = '';
  followers: number = 0;
  storage: any = {};
  userSession: any;
  btnFollowDisabled: boolean = false;
  isFollowing: boolean = false;
  isOwner: boolean = false;
  freeAccess: boolean = false;

  constructor(private route: ActivatedRoute,
              private collectionService: CollectionService,
              private alertify: AlertifyService,
              private authenticateService: AuthenticateService,
              private router: Router) {
    
    this.route.params.subscribe( params => {
      const query = {slug: params.slug};
      this.collectionService.getCollection(query).subscribe((collection: any) => {
        if (collection.length > 0) {
          this.collection = collection[0];
          this.collection.created_at = new Date(this.collection.created_at);
          this.collection.images.map(img => img.ml.created_at = new Date(img.ml.created_at));
          this.followers = this.collection.followers.length;
          this.mainImage = collection[0].images.length > 0 ? this.uriImages + 'mls/' + collection[0].images[0].ml._id + '/site/' + collection[0].images[0].site : '';
          this.storage.session = JSON.parse(sessionStorage.getItem('current-user'));
          this.storage.local = JSON.parse(localStorage.getItem('current-user'));
          if ( this.storage.session == null && this.storage.local == null ) {
            this.btnFollowDisabled = true;
          } else {
            if ( this.storage.session != null ) {
              this.userSession = this.storage.session;
            } else {
              this.userSession = this.storage.local;
            }
  
            if (this.userSession._id == this.collection.user._id) {
              this.btnFollowDisabled = true;
              this.isOwner = true;
            } else {
              this.checkIsFollowing();
            }
          }
  
          this.checkAccess(this.collection.public, this.collection.user._id);
        } else {
          this.router.navigate(['error/404']);
        }

      });

    })
  }

  ngOnInit() {
  }

  checkAccess(isPublic, userId) {
    if (isPublic) {
      this.freeAccess = true;
    } else {
      const user = this.authenticateService.isLogged();
      if (user !== null) {
        if (user._id === userId || this.authenticateService.isAdmin()) {
          this.freeAccess = true;
        }
      }
    }
  }

  checkIsFollowing() {
    this.isFollowing = false;
    this.collection.followers.filter((element) => {
      if (element._id == this.userSession._id) {
        this.isFollowing = true;
      }
    });
  }

  follow(id) {
    if (!this.btnFollowDisabled) {
      const data = {
        '_id': id,
        'mod': {
          'followers' : this.userSession._id
        }
      };
  
      if (this.isFollowing) {
        this.collectionService.unfollowCollection(data).subscribe((res: any) => {
          if (res.nModified) {
            const index = this.collection.followers.indexOf(this.userSession._id);
            this.collection.followers.splice(index, 1);
            this.checkIsFollowing();
            this.followers--;
          }
        });  
      } else {
        this.collectionService.followCollection(data).subscribe((res: any) => {
          if (res.nModified) {
            this.collection.followers.push({_id: this.userSession._id, firstname: this.userSession.firstname, lastname: this.userSession.lastname});
            this.checkIsFollowing();
            this.followers++;
          }
        });
      }
    }
  }

  formatToolTipSingle(obj){

    let tooltip = "";

    obj.ancestors.forEach(element => {
      tooltip += element.name + " >> ";
    });

    tooltip += obj.name;    

    return tooltip;
  }

  formatToolTipArray(obj){

    let tooltip = "";

    obj.forEach(element => {
      tooltip += " | " + element.name;
    });

    return tooltip.substr(3);
  }

  onOpened(type: string) {}

  removeImage(idCollection, image, index) {
    const msg = `Remove image?`;
    this.alertify.confirm('Attention', msg,
      () => {
        const data = {
          '_id': idCollection,
          'mod': {
            'images' : image._id
          }
        }
        this.collectionService.removeImageFromCollection(data).subscribe((res: any) => {
          if (res.nModified) {
            this.collection.images.splice(index, 1);
          }
        });
      },
      () => {}
    );
  }

}
