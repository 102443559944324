import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {

  SECERET_KEY = 'salt_from_the_user_document';
  // SECERET_KEY = 'aes-256-cbc';

  constructor() { }

  decrypt(value: string): string {
    // Decrypt 
    var bytes = CryptoJS.AES.decrypt(value, this.SECERET_KEY);
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.SECERET_KEY).toString();
  }

}
