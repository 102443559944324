import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../_services/collection.service';
import { globalVariables } from '../_globals/variables';

@Component({
  selector: 'app-collections',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

  collections: any = [];
  currentPage: any = {};
  uriBanners = globalVariables['URI_BANNERS'];
  searchObj = { keyword: "" };
  searching = false;
  loadMoreLabel = "Load more...";

  constructor(private collectionService: CollectionService) { }

  ngOnInit() {
    this.search();
  }

  search(query = null, page = null){
    this.searching = true;
    this.loadMoreLabel = "Searching...";
    this.collectionService.processSearch(query, page).subscribe((res: any) => {
      this.currentPage = res;
      const docs = this.currentPage.docs;

      // Caso o resultado seja page == 1
      // É resultado de uma pesquisa
      // limpa atribui o resultado ao objeto
      if(this.currentPage.page == 1 ){
        this.collections = docs;
      }else {
        // Caso a page seja > 1, é resultado da ação loadMore
        // Concatena no array
        this.collections = this.collections.concat(docs);
      }

      this.searching = false;
      this.loadMoreLabel = 'Load more...';
    });
  }

  loadMore(page){
    this.search(null, ++page);
  }

}
