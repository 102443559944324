import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-modal-list-users',
  templateUrl: './modal-list-users.component.html',
  styleUrls: ['./modal-list-users.component.scss']
})
export class ModalListUsersComponent implements OnInit {

  @Input() data: any;

  filter_users: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  filterUsers() {}

}
