import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AuthenticateService } from './../_services/authenticate.service';
declare var $:any;

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private auth: AuthenticateService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if ( this.check() ) {
      return true;
    }
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if ( this.check() ) {
      return true;
    }
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
  }


  check() {
    return true;
  }

  // canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {


  //   console.log('i am checking to see if you are logged in');
  //   let current_user = null;
  //   if ( sessionStorage.getItem("current-user") != null){
  //     current_user = JSON.parse( sessionStorage.getItem("current-user") );
  //   }else if( localStorage.getItem("current-user") != null ){
  //     current_user = JSON.parse( localStorage.getItem("current-user") );
  //   }

  //   if(current_user == null){
  //     $('#LoginModal').modal('toggle');
  //     return false;
  //   }else
  //     return true;
  // }

  // canActivateChild() {
  //   console.log('checking child route access');
  //   return true;
  // }

}
