import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ViewMaterialService } from "app/_services/view-material.service";
import { AuthenticateService } from "app/_services/authenticate.service";
import { HomeService } from 'app/_services/home.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  banner: boolean = false;
  logged: boolean = false;
  user: any;
  storage: any = {};
  randomImage = "";

  constructor(private viewMaterialService: ViewMaterialService, private authenticateServe: AuthenticateService, private homeService: HomeService) {
    authenticateServe.usersession$.subscribe( usersession => {
      if ( usersession == null ){
        this.logout();
        this.logged = false;
      }else{
        this.user = usersession;
        // this.user.photo = this.user.photo ? this.user.photo : './assets/images/profile.png';
        this.logged = true;
        this.setStatus(false);
      }
    });
  }

  ngOnInit() {
    // if ( location.pathname.indexOf('view-material') > 0 ) this.banner = true;
    //Se não estiver na home, mostra o header
    if(location.pathname !== "/"){
      $('.header-container').show();
    }

    this.storage.session = JSON.parse(sessionStorage.getItem('current-user'));
    this.storage.local = JSON.parse(localStorage.getItem('current-user'));
    if ( this.storage.session == null && this.storage.local == null ) {
      this.logged = false;
    }else{
      if ( this.storage.session != null ) {
        this.user = this.storage.session;
      }else{
        this.user = this.storage.local;
      }
      this.logged = true;
      this.setStatus(false);
    }

    this.homeService.getRandomImage().subscribe(res=>{
      if(res.img128.length > 0) this.randomImage = res.img128;
      else this.randomImage = res.img;

      //Salva no sharedservice para ser usado em outro componente
      this.homeService.setrandomImage(this.randomImage);
    })

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {

    if(location.pathname == "/"){
      let number = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      if (number > 150) {
        $('.header-container').slideDown();
      } else {
        $('.header-container').slideUp();
      }

    }
    
  }

  showMenuProfile(){
    $('#profile-box').show();
  }

  hideMenuProfile(){
    $('#profile-box').hide();
  }

  setStatus(status){
    this.viewMaterialService.setBannerStatus(status);
    this.banner = false;
  }

  logout() {
    this.authenticateServe.clearCredentials().subscribe( res => {
      this.logged = false;
      location.href = '/';
    });
  }

  openModalProfile() {
    $('#ProfileModal').modal('show');
  }

}
