import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
// import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';

import { globalVariables } from '../_globals/variables';
import { Subject } from "rxjs/Subject";

@Injectable()
export class ViewMaterialService {

  apiEndpoint = globalVariables['URI_API_PRODUCTION'];

  // constructor( private http: HttpClient ) { }
  constructor( private http: Http ) { }

  /* SET BANNER STATUS - BEGIN */
  private bannerStatus = new Subject<any>();
  bannerStatus$ = this.bannerStatus.asObservable();
  setBannerStatus(visible: boolean) {
    this.bannerStatus.next(visible);
  }
  /* SET BANNER STATUS - END */

  getMlImage(_id){
    return this.http.get( this.apiEndpoint + '/ml/findByImageId/'+_id)
    .map((res:Response) => res.json());
  }

  updateTotalViews(image_id){
    return this.http.post( this.apiEndpoint + '/ml/updateViews/', {"image_id": image_id })
    .map((res:Response) => res.json());
  }

  findMl(query){
    return this.http.post( this.apiEndpoint + '/ml/findParsed', query)
    .map((res:Response) => res.json());
  }

}
