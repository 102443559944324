import { CollectionService } from './../../_services/collection.service';
import { Component, OnInit, Input } from '@angular/core';
import { globalVariables } from 'app/_globals/variables';
import { AlertifyService } from '../../_services/alertify.service';
declare var $: any;

@Component({
  selector: 'app-thumb-collection',
  templateUrl: './thumb-collection.component.html',
  styleUrls: ['./thumb-collection.component.scss']
})
export class ThumbCollectionComponent implements OnInit {

  GLOBAL: any = globalVariables; 
  user: any;
  userSession: any;
  userLogged: any;
  value_btn: any;
  storage: any = {};
  btnFollowDisabled: boolean = false;
  isFollowing: boolean = false;
  isPublic: boolean = false;
  myCollection: boolean = false;
  submitted: boolean = false;

  @Input() collection: any;

  constructor(private collectionService: CollectionService, private alertify: AlertifyService) {}

  ngOnInit() {

    this.parseImages();

    $(document).ready(function(){
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.storage.session = JSON.parse(sessionStorage.getItem('current-user'));
    this.storage.local = JSON.parse(localStorage.getItem('current-user'));
    if ( this.storage.session == null && this.storage.local == null ) {
      this.btnFollowDisabled = true;
    } else {
      if ( this.storage.session != null ) {
        this.userSession = this.storage.session;
      } else {
        this.userSession = this.storage.local;
      }

      if (this.userSession._id == this.collection.user._id) {
        this.btnFollowDisabled = true;
        this.myCollection = true;
      } else {
        this.checkIsFollowing();
      }
    }
    
    if (this.collection.public != undefined && this.collection.public) {
      this.isPublic = true;
    }

  }

  checkIsFollowing() {
    this.isFollowing = false;
    this.collection.followers.filter((element) => {
      if (element._id == this.userSession._id) {
        this.isFollowing = true;
      }
    });
  }


  parseImages() {
    this.collection.images.forEach(element => {
      element.path = this.GLOBAL.URI_IMAGES + 'mls/' + element.ml + '/thumb/' + element.thumb;
    });

    // Caso o arry tenha menos de 3 imagens, completa com o placeholder
    if (this.collection.images.length === 0) {
      this.collection.images.push(
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'},
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'},
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'},
      );
    } else if (this.collection.images.length === 1) {
      this.collection.images.push(
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'},
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'},
      );
    } else if (this.collection.images.length === 2) {
      this.collection.images.push(
        {path: this.GLOBAL.URI_IMAGES + 'thumb_placeholder.png'}
      );
    }
  }

  follow(collection) {
    if (!this.btnFollowDisabled) {
      const data = {
        '_id': collection._id,
        'mod': {
          'followers' : this.userSession._id
        }
      };
  
      if (this.isFollowing) {
        this.collectionService.unfollowCollection(data).subscribe((res: any) => {
          if (res.nModified) {
            const index = this.collection.followers.indexOf(this.userSession._id);
            this.collection.followers.splice(index, 1);
            this.checkIsFollowing();
          }
        });  
      } else {
        this.collectionService.followCollection(data).subscribe((res: any) => {
          if (res.nModified) {
            this.collection.followers.push({_id: this.userSession._id, firstname: this.userSession.firstname, lastname: this.userSession.lastname});
            this.checkIsFollowing();
          }
        });
      }
    }
  }

  openViewCollection(collection) {
    this.submitted = true;
    const mod = {
      views: collection.views + 1
    }
    this.collectionService.updateCountViewes(collection._id, mod).subscribe((res: any) => {
      this.submitted = false;
      location.href = `collections/${collection.slug}`;
    });
  }

  changePrivacy(collection) {
    
    if (!collection.public && collection.public_request) {
      this.alertify.alert('Attention', 'Request already completed, awaiting approval.', () => {});
    } else {
      const msg = collection.public
                  ? `Do you want to make the <b>${collection.title}</b> collection private?`
                  : `Do you want to make the <b>${collection.title}</b> collection public?`;
      this.alertify.confirm('Attention', msg,
        () => {
            let mod = {'public': false, 'public_request': false};
            if (!collection.public) {
              mod = {'public': false, 'public_request': true};
            }
            this.collectionService.updateCollection(collection._id, mod).subscribe((res: any) => {
              if (res.nModified > 0) {
                this.collection.public = mod.public;
                this.collection.public_request = mod.public_request;
                if (!mod.public) {
                  this.isPublic = false;
                }
              }
            });
        },
        () => {}
      );
    }

  }
  
}
