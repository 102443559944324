import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs/Subject';

@Injectable()
export class FilterService {

   constructor() { }

  // Observable string sources
  private getIdSource = new Subject<any>();
  private getVisibleStatus = new Subject<any>();
  private getProcessSearch = new Subject<any>();

  // Observable string streams
  getIdSource$            = this.getIdSource.asObservable();
  getVisibleStatus$       = this.getVisibleStatus.asObservable();
  getProcessSearchStatus$ = this.getProcessSearch.asObservable();

   // Service message commands
  setId(obj: any) {
    this.getIdSource.next(obj);
  }

  setVisible(visible: boolean){
    this.getVisibleStatus.next(visible);
  }

  setProcessSearch(p: boolean){
    this.getProcessSearch.next(p);
  }

 

}
