import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ContentsService } from 'app/_services/contents.service';

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})
export class ContentsComponent implements OnInit {

  mls: any = [];
  allMls: any = [];
  p: any = '';
  ml: '';

  /*
  * Usado para melhoar o SEO
  */
  schema: any = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'name': 'Materials Life site contents',
    'url': 'https://materials.life/contents',
  }

  constructor(private title: Title, private contentsService: ContentsService) { }

  ngOnInit() {
    this.title.setTitle('Contents');
    this.schema.description = "Contents";
    this.contentsService.listContents().subscribe( (res: any) => {
      this.mls = res;
      this.allMls = res;
    });
  }

  filterMls() {
    if (!this.ml) {
      this.mls = [...this.allMls];
    } else {
      this.mls = [];
      this.mls = this.allMls.filter((item) => {
        return item.id.toUpperCase().indexOf(this.ml.toUpperCase()) > -1;
      });
    }
  }

}
