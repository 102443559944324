import { Component, OnInit } from '@angular/core';
import { SearchResultsService } from "app/_services/search-results.service";
import { globalVariables } from '../_globals/variables';
import { SrFilterPipe } from '../_pipes/sr-filter.pipe';
import { HelpersService } from 'app/_services/helpers.service';
import { BannerService } from '../_services/banner.service';
import { AuthenticateService } from 'app/_services/authenticate.service';
import alertify from 'alertifyjs';
declare var $: any;

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  uriBanners = globalVariables['URI_BANNERS'];
  searchObj: any;
  images: any = [];
  imagesFilter: any = [];
  showFilters = false;
  sortBy = "";
  showSortDropDown = false;
  currentSortFilter = "Default";
  noResults = false;
  loaded = false;
  filters = {
    title: "",
    material: { checkAll: true, items:[] },  product: { checkAll: true, items:[] }, application:{ checkAll: true, items:[] }, condition: { checkAll: true, items:[] },
    currentFilter: ""
  };
  banner: any;
  imageSelected: any;

  constructor(
    private searchService: SearchResultsService,
    private helpersService: HelpersService,
    private bannerService: BannerService,
    private authenticateService: AuthenticateService
  ) {

    this.searchObj = JSON.parse(localStorage.getItem("mlife-search-obj"));

    this.searchService.processSearch(this.searchObj).subscribe( res => {
      this.images = res;
      if(res.length == 0) this.noResults = true;



      // Preparação dos filtros
      // =================================================================================================================================
      this.images.forEach(element => {

        //material
        let item = this.filters.material.items.find(m=> m._id == element.mlProperties.material._id);
        if(item == undefined){
          element.mlProperties.material.checked = true;  // utilizado no filtro
          this.filters.material.items.push(element.mlProperties.material);
        } 

        //products
        //Pega apenas o último product.
        //Melhorar essa função para pegar todos
        item = this.filters.product.items.find(m=> m._id == element.mlProperties.products[element.mlProperties.products.length-1]._id);
        if(item == undefined){
          element.mlProperties.products[element.mlProperties.products.length-1].checked = true;  // utilizado no filtro
          this.filters.product.items.push(element.mlProperties.products[element.mlProperties.products.length-1]);
        } 

        //Criado para adequar o formato do elemento e fazer o filtro, pois o elemento original é um array contendo array
        element.mlProperties.productsFormatted = element.mlProperties.products[element.mlProperties.products.length-1];

        //Applications
        //Pega apenas o último application.
        //Melhorar essa função para pegar todos
        item = this.filters.application.items.find(m=> m._id == element.mlProperties.applications[element.mlProperties.applications.length-1]._id);
        if(item == undefined){
          element.mlProperties.applications[element.mlProperties.applications.length-1].checked = true;  // utilizado no filtro
          this.filters.application.items.push(element.mlProperties.applications[element.mlProperties.applications.length-1]);
        } 

        //Criado para adequar o formato do elemento e fazer o filtro, pois o elemento original é um array contendo array
        element.mlProperties.applicationsFormatted = element.mlProperties.applications[element.mlProperties.applications.length-1];
       

        //Condition
        item = this.filters.condition.items.find(m=> m._id == element.mlProperties.condition._id);
        if(item == undefined){
          element.mlProperties.condition.checked = true;  // utilizado no filtro
          this.filters.condition.items.push(element.mlProperties.condition);
        } 

      });
      // FILTROS ===============================================================================================================================================


      this.imagesFilter = this.images;

      //Ordena por nome
      // exemplos:  https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      this.filters.material.items.sort((a,b)=>{
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
   
      this.loaded = true;

    })


   }

  ngOnInit() {
    // $('a #contribuition').on('click',function(e){
    //     e.preventDefault();
    // });
  }

  // processSearch(){
  //   localStorage.setItem("mlife-search-obj", JSON.stringify(this.searchObj));
  //   location.href = "search";
  // }


  formatToolTipSingle(obj){

    let tooltip = "";

    obj.ancestors.forEach(element => {
      tooltip += element.name + " >> ";
    });

    tooltip += obj.name;    

    return tooltip;
  }

  formatToolTipArray(obj){

    let tooltip = "";

    obj.forEach(element => {
      tooltip += " | " + element.name;
    });

    return tooltip.substr(3);
  }


  toggleSortoDropDwon(){
    this.showSortDropDown = !this.showSortDropDown;
  }

  applySortFilter(option){

    this.currentSortFilter = option;

     if(option == "Default"){

      this.imagesFilter.sort(function(a, b) {
          return (a.descriptionView.id - b.descriptionView.id);
      });

    }else if(option == "Most recent"){
      console.log("Entrei aqui: 1")
      this.imagesFilter.sort(function(a, b) {
          return (new Date(a.mlProperties.created_at).getTime() - new Date(b.mlProperties.created_at).getTime() );
      });

    }else if(option == "Visualizations (ASC)"){

      this.imagesFilter.sort(function(a, b) {
          return parseFloat(a.totalViews) - parseFloat(b.totalViews);
      });

    }else if(option == "Visualizations (DESC)"){

      this.imagesFilter.sort(function(a, b) {
          return parseFloat(b.totalViews) - parseFloat(a.totalViews);
      });
    }
  }

  checkboxChange(e, item, type){
    // console.log(e);

    //Verifica se existe pelo meno um checkbox marcado
    let filters = this.filters[type].items.filter( f =>{
      return f.checked == true;
    })

    if(filters.length > 0){

      if(this.filters.currentFilter != this.filters[type]){
        
              console.log("Resetar filtro");
        
              //Não pode resetar o item atual
              if(type != "material"){
        
                this.filters.material.items.forEach(element => {
                  element.checked = true;
                });
        
              }
        
              //Não pode resetar o item atual
              if(type != "product"){
        
                this.filters.product.items.forEach(element => {
                  element.checked = true;
                })
              }

              if(type != "application"){
                
                this.filters.application.items.forEach(element => {
                  element.checked = true;
                })
              }

              //Não pode resetar o item atual
              if(type != "condition"){
                
                this.filters.condition.items.forEach(element => {
                  element.checked = true;
                })
              }
        
              this.filters.currentFilter = type;
              
            }
        
              let values = [];
              
              this.filters[type].items.forEach(element => {
                if(element.checked) values.push(element._id);
              });


              if(type == "product"){

                this.imagesFilter = this.images.filter( img=>{       
                  //Verifica se o _id do item está presente no array de filtros marcados
                  return values.indexOf(img.mlProperties.productsFormatted._id) >=0;
                });

              }else if(type == "application"){
                
                this.imagesFilter = this.images.filter( img=>{       
                  //Verifica se o _id do item está presente no array de filtros marcados
                  return values.indexOf(img.mlProperties.applicationsFormatted._id) >=0;
                });

              }else{

                this.imagesFilter = this.images.filter( img=>{       
                  //Verifica se o _id do item está presente no array de filtros marcados
                  return values.indexOf(img.mlProperties[type]._id) >=0;
                });

              }
          
          

    }else{
      item.checked = true;
      alert("Please keep at least one item checked.");
    }

    // console.log(this.filters);

  }

  checkboxCheckAllChange(e, item, type){
    item.items.forEach(element => {
      element.checked = item.checkAll;
    });

    if(item.checkAll){
      this.imagesFilter = this.images;
    }else this.imagesFilter = [];
  }

  openModalAddImageToCollection(item) {
    if (this.authenticateService.isLogged() !== null) {
      $('#AddImageToCollectionModal').modal('show');
      this.imageSelected = item;
    } else {
      alertify.alert('You must log in to add images to collections.', function () {
          console.log("Ok");
      });
    }
  }

}
