import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from "app/_services/user.service";
import { AuthenticateService } from "app/_services/authenticate.service";
declare var $: any;

function confirmPassword(input: FormControl) {
  if ( !input['root']['controls'] ) {
    return null;
  }
  const exactMatch = input['root']['controls']['new_password']['value'] === input.value;
  return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
  selector: 'app-modal-profile',
  templateUrl: './modal-profile.component.html',
  styleUrls: ['./modal-profile.component.scss']
})
export class ModalProfileComponent implements OnInit {

  currentStep: number = 1;
  submitted: boolean = false;
  emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  error_message: string = '';
  error_name: string = '';
  user: any = {};
  storage: string = '';
  alert: any = {
    status: {
      success: false,
      error: false
    },
    message: {
      success: '',
      error: ''
    }
  };

  showFbLinkButton: boolean = false

  first_name =  new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  last_name = new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  email = new FormControl('', [
    Validators.required, 
    Validators.pattern(this.emailRegex), 
    Validators.maxLength(150)
  ]);

  password = new FormControl('', [
    Validators.required
  ]);

  new_password = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20)
  ]);

  repassword = new FormControl('', [
    Validators.required, 
    confirmPassword
  ]);

  noValidate = new FormControl(true, []);

  formEditProfile: FormGroup = this.builder.group({
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email
  });

  formEditPassword: FormGroup = this.builder.group({
    password: this.password,
    new_password: this.new_password,
    repassword: this.repassword,
  });

  constructor(private builder: FormBuilder, private userService: UserService, private authenticateService: AuthenticateService) {
    authenticateService.usersession$.subscribe( usersession => {
      if ( usersession != null ){
        this.user = usersession;
        this.formEditProfile.controls['first_name'].setValue(this.user.firstname);
        this.formEditProfile.controls['last_name'].setValue(this.user.lastname);
        this.formEditProfile.controls['email'].setValue(this.user.email);

        if(this.user.auth.hasOwnProperty("facebook")){
          if(this.user.auth.facebook.email == null) this.showFbLinkButton = true;
        }else this.showFbLinkButton = true
      }
    });
  }

  ngOnInit() {
    if ( sessionStorage.getItem("current-user") != null || localStorage.getItem("current-user") != null ){
      if ( sessionStorage.getItem("current-user") != null ){
        this.user = JSON.parse(sessionStorage.getItem("current-user"));
        this.storage = 'session';
      }else{
        this.user = JSON.parse(localStorage.getItem("current-user"));
        this.storage = 'local';
      }
      this.formEditProfile.controls['first_name'].setValue(this.user.firstname);
      this.formEditProfile.controls['last_name'].setValue(this.user.lastname);
      this.formEditProfile.controls['email'].setValue(this.user.email);
      if(this.user.auth.hasOwnProperty("facebook")){
        if(this.user.auth.facebook.email == null) this.showFbLinkButton = true;
      }else this.showFbLinkButton = true;
    }
  }

  closeModal(){
    this.formEditPassword.reset();
    this.currentStep = 1;
    $('#ProfileModal').modal('hide');
  }

  onSubmitProfile(){
    this.submitted = true;
    if ( this.formEditProfile.valid ) {

      const data = {
        _id: this.user._id,
        firstname: this.formEditProfile.controls['first_name'].value,
        lastname: this.formEditProfile.controls['last_name'].value,
        email: this.formEditProfile.controls['email'].value
      };

      this.userService.updateUser(data).subscribe((res: any) => {
        if ( res.ok ){
          this.user.firstname = data.firstname;
          this.user.lastname = data.lastname;
          this.user.email = data.email;
          
          if ( this.storage == 'session'){
            sessionStorage.setItem('current-user', JSON.stringify(this.user));
          }else{
            localStorage.setItem('current-user', JSON.stringify(this.user));
          }

          this.submitted = false;
          this.setAlert('success', 'Profile successfully changed');
        }else{
          this.setAlert('error', 'Error changing profile');
          this.submitted = false;
        }
      });
    }else{
      this.setAlert('error', 'Fill the required fields');
      this.submitted = false;
    }
    
  }

  onSubmitPassword(){
    this.submitted = true;
    if ( this.formEditPassword.valid ) {
      const auth = { email: this.user.email, password: this.formEditPassword.controls['password'].value }
          , data = { _id: this.user._id, password: this.formEditPassword.controls['new_password'].value }
          ;

      this.authenticateService.login(auth).subscribe((res: any) => {
        this.userService.updatePassword(data).subscribe((res: any) => {
          if ( res.ok ){
            this.formEditPassword.reset();
            this.submitted = false;
            this.setAlert('success', 'Password successfully changed');
          }else{
            this.setAlert('error', 'Error changing password');
            this.submitted = false;
          }
        });
      },
      error => { 
        this.setAlert('error', 'Current password invalid');
        this.submitted = false;
      });
      
    }else{
      this.setAlert('error', 'Fill the required fields');
      this.submitted = false;
    }

  }

  setAlert(type = 'clear', message = '', delay = 6000 ){
    if ( type == 'success' ){
      this.alert = {
        status: {
          success: true,
          error: false
        },
        message: {
          success: message,
          error: ''
        }
      }
    }else if ( type == 'error' ){
      this.alert = {
        status: {
          success: false,
          error: true
        },
        message: {
          success: '',
          error: message
        }
      }
    }else{
      this.alert = {
        status: {
          success: false,
          error: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    }

    setTimeout( () => { this.setAlert(); }, delay);

  }

  linkToFacebook(){
    this.authenticateService.loginWithFacebook().then( facebookUser =>{
      console.log(facebookUser);

      const data = {
        _id: this.user._id,
        "auth.facebook": {"email": facebookUser['email'], "id": facebookUser['id']}
      };

      this.userService.updateUser(data).subscribe((res: any) => {
        if ( res.ok ){
          this.user.auth.facebook = {"email": facebookUser['email'], "id": facebookUser['id']};
          if(this.storage == "session"){
            sessionStorage.setItem("current-user", JSON.stringify(this.user));
          }else
            localStorage.setItem("current-user", JSON.stringify(this.user));

          this.showFbLinkButton = false;
          this.setAlert('success', "Your account is linked to Facebook, next time you will be able to login with Facebook.", 15000)
        }
      });
    });
  }

}
