import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConvertDollarToRealService {

  constructor(public http: HttpClient) {}

  convert(cb) {
    const currentDate = new Date();
    const yesterday   = new Date(currentDate.setDate(currentDate.getDate() - 1));
    const dateValid = this.getDateValid(yesterday);
    const year  = dateValid.getFullYear();
    const month = dateValid.getMonth()+1;
    const day   = dateValid.getDate(); 
    const dt = (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + '-' + year;
    const objQuotation = this.http.get(`https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao=%27${dt}%27&$top=2&$skip=0&$format=json&$select=cotacaoCompra`);
    objQuotation.subscribe(res => {
      let quotation = null;
      if (res['value'].length > 0) {
          quotation = res['value'][0]['cotacaoCompra'];
      }
      return cb({
        quotation: quotation,
        quotationdate: dt
      });
    });
  }

  getDateValid(yesterday) {
    if (yesterday.getDay() == 6) {
      return new Date(yesterday.setDate(yesterday.getDate() - 1));
    } else if (yesterday.getDay() == 0) {
      return new Date(yesterday.setDate(yesterday.getDate() - 2));
    } else {
      return yesterday;
    }
  }

}
