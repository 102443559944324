import { Component, OnInit, Input } from '@angular/core';
import { BannerService } from '../../_services/banner.service';
import { Logs } from 'selenium-webdriver';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() query: any;
  @Input() type: string;
  @Input() target_window: string = '_blank';

  data: any = {};
  lastBannerViewed = '';
  currentUser = null;
  
  constructor(public bannerService: BannerService) {

    // Check if user is looged in
      if ( sessionStorage.getItem("current-user") != null){
        this.currentUser = JSON.parse( sessionStorage.getItem("current-user") );
      }else if( localStorage.getItem("current-user") != null ){
        this.currentUser = JSON.parse( localStorage.getItem("current-user") );
      }
  }

  ngOnInit() {
    this.data = {
      visible: false,
      link: '',
      slug: '',
      title: '',
      titleRight: '',
      imageHD: '',
      imageMD: '',
      imageXS: '',
      alt: '',
      icon: '',
    };
  }
  ngOnChanges() {
    if(this.query.hasOwnProperty('query')) {
      this.getBanners(this.query);
    }
  }

  getBanners(query) {
    this.bannerService.getBanners(query).subscribe((res : any) => {
      if (res.length === 1) { 
        this.setBanner(res[0]); 
      } else if (res.length > 1) {
        this.getRandomBanner(res);
      }
    });
  }

  setBanner(banner) {
    this.data = {
      visible: true,
      link: banner.link,
      title: banner.title,
      titleRight: banner.titleRight,
      slug: banner.slug,
      imageHD: banner.image.hd,
      imageMD: banner.image.md,
      imageXS: banner.image.xs,
      alt: banner.name,
      icon: banner.icon,
    };

    // Save statistics
    this.saveBannerStatistics(banner._id);

  }

  getRandomBanner(banners) {

    let lastBannerViewed = '';
    // Check for the last banner viewed
    if ( sessionStorage.getItem('last-banner-viewed') != null) {
      lastBannerViewed = sessionStorage.getItem('last-banner-viewed');
    }

    const max = banners.length - 1;

    // get a random number between 0 and banners array length -1
    const randomIndex = Math.floor(Math.random() * Math.floor(max + 1));

    if (banners[randomIndex]._id !== lastBannerViewed) {
      sessionStorage.setItem('last-banner-viewed', banners[randomIndex]._id);
      this.setBanner(banners[randomIndex]);
    } else if (randomIndex + 1 <= max) {
      // Show next banner if index is lower than max size
      this.setBanner(banners[randomIndex + 1]);
    } else {
      this.setBanner(banners[0]);
    }

  }


  saveBannerStatistics(bannerId) {

    const navigatorInfo = {
      appCodeName: window.navigator.appCodeName,
      appName: window.navigator.appName,
      appVersion: window.navigator.appVersion,
      platform: window.navigator.platform,
      product: window.navigator.product,
      userAgent: window.navigator.userAgent,
    };

    const statisticObj = {
      banner: bannerId,
      user: this.currentUser,
      screenResolution: {
        width: screen.width,
        height: screen.height
      },
      navigatorInfo: navigatorInfo,
    };
    this.bannerService.saveStatistics(statisticObj).subscribe();
  }

  navigateToUrl(url) {
    if (url != '#') {
      window.location.href=url;
    } 
  }
}
