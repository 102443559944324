import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from "app/_services/user.service";
import { globalVariables } from '../_globals/variables';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  
  user: any = {};
  GLOBAL: any = globalVariables; 
  processing: boolean = true;
  data_to_profile: any = {};


  constructor(private userService: UserService, private route: ActivatedRoute, ) {
    this.route.params.subscribe( params => {
      this.userService.getUser(params.id).subscribe((res: any) => {
        this.user = res[0];
        this.data_to_profile = {
          user: this.user
        };
        this.processing = false;
      },
      error => {
        location.href = "404";
      });
    });
  }

  ngOnInit() {
    
  }

}
