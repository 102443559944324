import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthenticateService } from "app/_services/authenticate.service";
import { UserService } from "app/_services/user.service";
// import { FacebookService, LoginResponse, LoginOptions, UIResponse, UIParams, FBVideoComponent } from 'ngx-facebook';
import { globalVariables } from '../_globals/variables';

declare var $: any;

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {

  currentStep: number = 1;
  submitted: boolean = false;
  facebook = false;
  msg_error: boolean = false;
  msg_error_txt: string = '';
  auth: any = {};
  page_view: boolean = false;
  alert = { visible: false, msg: "", success: true }

  email = new FormControl('', [
    Validators.required
  ]);

  password = new FormControl('', [
    Validators.required
  ]);

  remember_me = new FormControl('', []);

  formGroupLogin: FormGroup = this.builder.group({
    email: this.email,
    password: this.password,
    remember_me: this.remember_me
  });

  formForgotPass: FormGroup = this.builder.group({
    email: this.email
  });

  constructor(
    private builder: FormBuilder,
    private authenticateService: AuthenticateService,
    private userService: UserService,
  ) {
    
    // fb.init({
    //   appId: '146334752661966',
    //   version: 'v2.10'
    // });
  }

  ngOnInit() {
    if ( location.pathname.indexOf('view-material') > 0 || location.pathname.indexOf('ml') > 0 ) this.page_view = true;
    if (sessionStorage.getItem('current-user')) {
      const user = JSON.parse(sessionStorage.getItem('current-user'));
      this.formGroupLogin.controls['remember_me'].setValue(true);
      this.formGroupLogin.controls['email'].setValue(user.email);
    }
  }

  onSubmitLogin() {
    this.submitted = true;
    this.msg_error = false;
    const remember_me = this.formGroupLogin.controls['remember_me'].value;

    if ( this.formGroupLogin.valid ) {
      this.auth.email = this.formGroupLogin.controls['email'].value;
      this.auth.password = this.formGroupLogin.controls['password'].value;
      this.authenticateService.login(this.auth).subscribe((response) => {
        const res = response.json();
        if (res.status == 200 ) {
          if ( res.user.active ) {
            
            if (typeof(Storage) !== 'undefined') {

              this.formGroupLogin.reset();
              
              if ( remember_me ) {
                sessionStorage.setItem('current-user', JSON.stringify(res.user));
                sessionStorage.setItem('token', JSON.stringify(res.token));
              } else {
                localStorage.setItem('current-user', JSON.stringify(res.user));
                localStorage.setItem('token', JSON.stringify(res.token));
              }

              this.authenticateService.setUserSession(res.user);
              this.msg_error_txt = '';
              this.msg_error = false;
              this.submitted = false;

              //para o modal signup pegar os dados do localstorage, para caso o usuario for escolher um plano, abra no step 3
              location.reload();
              $("#LoginModal").modal('hide');

            } else {

              this.msg_error_txt = 'Sorry! No Web Storage support.';
              this.msg_error = true;
              this.submitted = false;
            }

          } else {
            this.msg_error_txt = 'User inactive.';
            this.msg_error = true;
            this.submitted = false;
          }

        } else {
          this.checkError(res.status, res.message);
        }
      },
      (error) => { 
        const err = error.json();
        this.checkError(err.status, err.message);
      });
      
    } else {
      this.msg_error_txt = 'Email or password invalid.';
      this.msg_error = true;
      this.submitted = false;
    }

  }

  checkError(status, message) {
    this.msg_error_txt = 'Email or password invalid.';
    if ( status == 500 ) {
      this.msg_error_txt = 'Internal server error.';
    }
    if ( status == 401 && message == 'incorrect_email' ) {
      this.msg_error_txt = 'Incorrect email.';
    }
    if ( status == 401 && message == 'incorrect_password' ) {
      this.msg_error_txt = 'Incorrect password.';
    }
    this.msg_error = true;
    this.submitted = false;
  }

  openFormSignUp(){
    $("#LoginModal").modal('hide');
    if ( this.page_view ){
      $('#SignupModal').modal({
        backdrop: 'static',
        keyboard: false
      });
      $('#SignupModal').on('shown.bs.modal', function (e) {
        $(".modal-backdrop.in").css({ opacity: 0.9 });
      });
    }else{
      $("#SignupModal").modal('show');
    }
  }
  onSubmitForgotPass(){

    this.alert.visible = false;
    
      let now = new Date();
  
      let hash = this.formForgotPass.controls['email'].value + ";"+now.getTime();
      hash = btoa(hash);
  
      let obj = {
        email: this.formForgotPass.controls['email'].value,
        hash: hash
      }
  
      this.userService.resetPasswordStep1(obj).subscribe((res: any) =>{
        if (res.success){
          this.alert.msg = "Check your inbox, you will receive the instructions to reset your password.";
          this.alert.success = true;
          this.alert.visible = true;
        } else {
          this.alert.msg = res.msg;
          this.alert.success = false;
          this.alert.visible = true;
        }
      });

  }

  loginWithFacebook(){

    this.facebook = true;

    const remember_me = this.formGroupLogin.controls['remember_me'].value;

    this.authenticateService.loginWithFacebook().then( facebookUser =>{
      this.authenticateService.processLoginWithFacebook(facebookUser).subscribe((res: any) =>{
        if ( res.success ){
          if( res.user.active ){
            if (typeof(Storage) !== 'undefined') {
              this.formGroupLogin.reset();
              if ( remember_me ){
                localStorage.setItem('current-user', JSON.stringify(res.user));
              }else{
                sessionStorage.setItem('current-user', JSON.stringify(res.user));
              }
              this.authenticateService.setUserSession(res.user);
              this.msg_error_txt = '';
              this.msg_error = false;
              this.submitted = false;
              this.facebook = false;
              $("#LoginModal").modal('hide');
            } else {
              this.msg_error_txt = 'Sorry! No Web Storage support.';
              this.msg_error = true;
              this.submitted = false;
              this.facebook = false;
            }
          }else{
            this.msg_error_txt = 'User inactive.';
            this.msg_error = true;
            this.submitted = false;
            this.facebook = false;
          }
        }else{
          this.msg_error_txt = "User not found.<br> If you have an account at Materials Life, please sign-in with your email and password and link your existing account to Facebook. You can find this feature at user's profile page.";
          this.msg_error = true;
          this.submitted = false;
          this.facebook = false;
        }


      })
    })
  }

}
