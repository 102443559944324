import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { HomeService } from "app/_services/home.service";
import { FilterStructureComponent } from "app/filter-structure/filter-structure.component";
import { FilterService } from "app/_services/filter.service";
import { UserService } from "app/_services/user.service";
import { HelpersService } from 'app/_services/helpers.service';
import { AuthenticateService } from '../_services/authenticate.service';
import alertify from 'alertifyjs';
import { CollectionService } from 'app/_services/collection.service';
import { DeviceDetectorService } from "ngx-device-detector";
declare var $: any;


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [FilterService],
})
export class HomeComponent implements OnInit {
  featuredImages = [];
  mostRecentImages = [];
  collectionsMostRecent = [];
  randomImage = "";
  searchObj = {
    keyword: "",
    filters: [],
  };
  currentFilter = "";
  currentLabel = "";
  filterVisible = false;
  plans: any;
  imageSelected: any;

  logged = false;

  @ViewChild("parent", { read: ViewContainerRef })
  parent: ViewContainerRef;
  childComponent: any;

  materials: any = [];
  materialsView: any = [];
  userSettings: any;

  deviceInfo = null;

  constructor(
    private homeService: HomeService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private filterService: FilterService,
    private userService: UserService,
    private helpersService: HelpersService,
    private authenticateService: AuthenticateService,
    private collectionService: CollectionService,
    private deviceService: DeviceDetectorService
  ) {
    // IMPLEMENTAR O Observable.forkJoin
    this.getFeaturedImages();
    this.getMostRecent();
    this.getCollectionsMostRecent();

    this.childComponent = this.componentFactoryResolver.resolveComponentFactory(
      FilterStructureComponent
    );

    // Recebe um evento do componente
    filterService.getIdSource$.subscribe((obj) => {
      this.getChildren(obj);
    });

    // Verifica se o objeto está visivel
    filterService.getVisibleStatus$.subscribe((obj) => {
      this.filterVisible = obj;
    });

    // verifica se ocorreu um duplo click no componente
    filterService.getProcessSearchStatus$.subscribe((obj) => {
      if (obj) this.processSearch();
    });

    this.listPlans();

    this.setupAlertify();

  }

  ngOnInit() {
    $(document).ready(function () {
      $(".ml-home-HowItWorks").slideDown();
    });

    this.homeService.randomImage$.subscribe((imagePath) => {
      this.randomImage = imagePath;
    });
  }

  closeBanner() {
    $(".ml-home-HowItWorks").slideUp();
  }

  getFeaturedImages() {
    this.homeService.getFeaturedImages().subscribe((res) => {
      this.featuredImages = res;
    });
  }

  getMostRecent() {
    this.homeService.getMostRecentImages().subscribe((res) => {
      this.mostRecentImages = res;
    });
  }

  //FUNÇÕES DO COMPONENTE DE BUSCA
  processSearch() {
    localStorage.setItem("mlife-search-obj", JSON.stringify(this.searchObj));
    location.href = "search";
  }

  someMethod(event: any) {
    if (event.keyCode == 13) this.processSearch();
  }

  uiSwitchChange(event, category, label) {
    this.currentFilter = category.toUpperCase();
    let item = category.toLowerCase();
    if (item == "capture.method") item = "captureMethod";
    if (item == "detail.focus") item = "detailFocus";

    this.currentLabel = label;
    if (event) {
      this.parent.clear();
      this.loadCategory(category, null);

      if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
        window.scroll({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      }
      
    } else {
      //Caso esteja desligando um dos filtros, remove do array
      for (var index = 0; index < this.searchObj.filters.length; index++) {
        if (this.searchObj.filters[index].item == item) {
          this.searchObj.filters.splice(index, 1);
        }
      }

      this.materialsView = [];
    }
  }

  handleCatChange(category, label) {
    //remove o primeiro item do componente
    //limpa o container até o nivel do item clicado
    let itemsCount = this.parent.length;
    for (let i = 0; i < itemsCount; i++) {
      this.parent.remove(i);
    }

    this.currentFilter = category.toUpperCase();
    this.currentLabel = label;

    //Utiliza o callback, para aguardar a busca dos materiais na API e depois executar a fillSearchComponenet()
    this.loadCategory(category, () => {
      let item = this.searchObj.filters.find((filter) => {
        return filter.item == category;
      });

      if (item) this.fillSearchComponent(item);
    });
  }

  loadCategory(category, callback) {
    this.homeService.getCategory(category).subscribe((data) => {
      this.formatObject(data);
      this.materials = data;

      if (callback) {
        callback();
      }
    });
  }

  formatObject(obj) {
    //pega os itens de nivel 0
    this.materialsView = obj.filter((item) => item.parent.length == 0);

    //Caso já exista no this.searchObj.filters, procura ele e colcoa como active = true
    if (this.searchObj.filters.length > 0) {
      let selected = this.searchObj.filters.find(
        (item) => item.item == this.currentFilter.toLowerCase()
      );
      if (selected) {
        // O selected é o item que já foi selecionado previamente ( que foi localizado dentro do this.searchObj.filters )
        // Como o item selecionado previamente é sempre o último item selecionado,
        // o materialsView que deve ser ativo, é o ancestor de nivel 0 do selected
        // se o item que ja foi selecionado não for de nivel 0
        let current = null;

        if (selected.ancestors.length > 0) {
          current = this.materialsView.find(
            (mv) => mv._id == selected.ancestors[0]._id
          );
        }
        //se o item selecionado for de nivel 0, ele proprio é o selecionado
        else current = this.materialsView.find((mv) => mv._id == selected.id);
        if (current) current.active = true;
      }
    }

    // let compFactory = this.componentFactoryResolver.resolveComponentFactory(this.childComponent);
    this.parent.createComponent(this.childComponent).instance["obj"] = {
      title: this.currentFilter,
      label: this.currentLabel,
      items: this.materialsView,
    };
  }

  getChildren(obj) {
    let item = this.currentFilter.toLowerCase();
    if (item == "capture.method") item = "captureMethod";
    if (item == "detail.focus") item = "detailFocus";

    //Caso não tenha nenhum filtro, adiciona ao array
    if (this.searchObj.filters.length == 0) {
      this.searchObj.filters.push({
        item: item,
        id: obj._id,
        name: obj.name.toLowerCase(),
        ancestors: obj.ancestors,
        hasChildren: obj.hasChildren,
      });

      //senão pesquisa se o tipo já existe no array e atualiza ou adiciona caso não exista
    } else {
      let existe = false;
      this.searchObj.filters.forEach((element) => {
        if (element.item == item) {
          element.id = obj._id;
          element.name = obj.name.toLowerCase();
          element.ancestors = obj.ancestors;
          element.hasChildren = obj.hasChildren;
          existe = true;
        }
      });
      if (!existe)
        this.searchObj.filters.push({
          item: item,
          id: obj._id,
          name: obj.name.toLowerCase(),
          ancestors: obj.ancestors,
          hasChildren: obj.hasChildren,
        });
    }

    let children = this.materials.filter((item) => item.parent == obj._id);
    //Coloca o campo active = false, para limpar a seleção
    for (let i = 0; i < children.length; i++) {
      children[i].active = false;
    }

    //limpa o container até o nivel do item clicado
    let itemsCount = this.parent.length;
    for (let i = obj.ancestors.length + 1; i < itemsCount; i++) {
      this.parent.remove(i);
    }

    //Cria o componente com os filhos
    if (children.length > 0)
      this.parent.createComponent(this.childComponent).instance["obj"] = {
        title: "",
        label: "",
        items: children,
      };
  }

  fillSearchComponent(obj) {
    //Percorre todos os ancestors e adiciona no componente
    for (let index = 0; index < obj.ancestors.length; index++) {
      let children = this.materials.filter(
        (item) => item.parent == obj.ancestors[index]._id
      );
      if (children.length > 0) {
        //o item ativo é o proximo ancestor
        if (index + 1 < obj.ancestors.length) {
          let selected = children.filter(
            (item) => item._id == obj.ancestors[index + 1]._id
          );
          if (selected) selected[0].active = true;
        } else {
          //Caso seja o ultimo ancestor, o filho selecionado é o item atual
          let selected = children.filter((item) => item._id == obj.id);
          if (selected) selected[0].active = true;
        }

        this.parent.createComponent(this.childComponent).instance["obj"] = {
          title: "",
          label: "",
          items: children,
        };
      }
    }
  }

  //Função que adiciona a classe active para o as <li>
  // do container s-container-right do componente de busca
  filterIsSelected(name) {
    let response = "";

    if (this.currentFilter.toLowerCase() == name) {
      response = "active";
    } else {
      //Verifica se o item está selecinado
      let exists = this.searchObj.filters.find((el) => {
        return el.item == name;
      });

      if (exists) response = "selected";
    }

    return response;
  }

  fillBreadCrumb(category) {
    let response = [];
    let item = this.searchObj.filters.find((filter) => {
      return filter.item == category;
    });

    if (item) {
      item.ancestors.forEach((element) => {
        response.push(element.name.toLowerCase());
      });
      response.push(item.name.toLowerCase());
    }
    return response;
  }

  uiSwitchHandleChecked(category) {
    let item = this.searchObj.filters.find((filter) => filter.item == category);

    if (item) {
      return true;
    } else return false;
  }

  listPlans() {
    this.userService
      .listPlans({ category: "Commercial", view: true })
      .subscribe((res) => {
        this.plans = res;
      });
  }

  openModalAddImageToCollection(item) {
    if (this.authenticateService.isLogged() !== null) {
      $("#AddImageToCollectionModal").modal("show");
      this.imageSelected = item;
    } else {
      alertify.alert(
        "You must log in to add images to collections.",
        function () {
          console.log("Ok");
        }
      );
    }
  }

  setupAlertify() {
    alertify.defaults = {
      // dialogs defaults
      autoReset: true,
      basic: false,
      closable: true,
      closableByDimmer: true,
      frameless: false,
      maintainFocus: true, // <== global default not per instance, applies to all dialogs
      maximizable: true,
      modal: true,
      movable: true,
      moveBounded: false,
      overflow: true,
      padding: true,
      pinnable: true,
      pinned: true,
      preventBodyShift: false, // <== global default not per instance, applies to all dialogs
      resizable: true,
      startMaximized: false,
      transition: "pulse",

      // notifier defaults
      notifier: {
        // auto-dismiss wait time (in seconds)
        delay: 5,
        // default position
        position: "bottom-right",
        // adds a close button to notifier messages
        closeButton: false,
      },

      // language resources
      glossary: {
        // dialogs default title
        title: "Materials Life",
        // ok button text
        ok: "OK",
        // cancel button text
        cancel: "Cancel",
      },

      // theme settings
      theme: {
        // class name attached to prompt dialog input textbox.
        input: "ajs-input",
        // class name attached to ok button
        ok: "ajs-ok",
        // class name attached to cancel button
        cancel: "ajs-cancel",
      },
    };
  }

  getCollectionsMostRecent() {
    this.collectionService.getCollectionsMostRecent().subscribe((res: any) => {
      this.collectionsMostRecent = res;
    });
  }
}
