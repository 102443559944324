import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modal-choose-your-plan',
  templateUrl: './modal-choose-your-plan.component.html',
  styleUrls: ['./modal-choose-your-plan.component.scss']
})
export class ModalChooseYourPlanComponent implements OnInit {

  currentStep: number = 1;
  plan_selected: string = '';

  constructor() { }

  ngOnInit() {
  }

  nextStep(id){
      this.currentStep++;
  }

}
