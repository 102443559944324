import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from 'app/_services/payment.service';
import { dataCountries } from '../_data/countries';
import { ConvertDollarToRealService } from 'app/_services/convert-dollar-to-real.service';

@Component({
  selector: 'app-modal-contribution',
  templateUrl: './modal-contribution.component.html',
  styleUrls: ['./modal-contribution.component.scss']
})
export class ModalContributionComponent implements OnInit {

  values: any = [5, 10, 25, 50];
  countries: Array<any>;
  // otherValue: number;
  valueSelected: number = 5;
  valueInArray: boolean = true;
  isInvalidSelection: boolean = true;
  currentStep = 1;
  emailRegex = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  dataContribuition: any = {
    first_name: '',
    last_name: '',
    email: ''
  };
  currencySymbol: string;

  first_name =  new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  last_name = new FormControl('', [
    Validators.required, 
    Validators.minLength(2), 
    Validators.maxLength(30)
  ]);

  email = new FormControl('', [
    Validators.pattern(this.emailRegex), 
    Validators.maxLength(150)
  ]);

  country_of_residence = new FormControl('', [
    Validators.required
  ]);

  value = new FormControl('', []);

  otherValue = new FormControl('', []);

  formGroupDonate: FormGroup = this.builder.group({
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email,
    country_of_residence: this.country_of_residence,
    value: this.value,
    otherValue: this.otherValue
  });

  constructor(
    private builder: FormBuilder,
    private paymentService: PaymentService,
    private convertDollarToRealService: ConvertDollarToRealService
  ) {
    this.valueSelected = 5;
    this.formGroupDonate.controls['value'].setValue(5);
  }

  ngOnInit() {
    this.currentStep = 1;
    this.countries = dataCountries['VALUES'];
  }

  onValueChange(value) {
    this.valueInArray = this.inArray(value);
    if (this.inArray(value) || (value != null && value != '' && value > 0)) {
      this.isInvalidSelection = false;
    } else {
      this.isInvalidSelection = true;
    }
  }

  inArray(v) {
    v = parseFloat(v);
    return this.values.indexOf(v) < 0 ? false : true;
  }

  setValue() {
    const ov = this.formGroupDonate.controls['otherValue'].value;
    if (this.isNumber(ov)) {
      this.formGroupDonate.controls['value'].setValue(ov);
      this.isInvalidSelection = false;
    } else {
      this.isInvalidSelection = true;
    }
  }

  isNumber(value: string | number): boolean
  {
    if (value == undefined) return false;
    return !isNaN(Number(value.toString()));
  }

  showSummary() {
    this.dataContribuition.first_name = this.formGroupDonate.controls['first_name'].value;
    this.dataContribuition.last_name = this.formGroupDonate.controls['last_name'].value;
    this.dataContribuition.email = this.formGroupDonate.controls['email'].value;
    this.dataContribuition.country_of_residence = this.formGroupDonate.controls['country_of_residence'].value;
    this.dataContribuition.value = this.formGroupDonate.controls['value'].value
                                 ? this.formGroupDonate.controls['value'].value
                                 : this.formGroupDonate.controls['otherValue'].value;
    this.currencySymbol = '$';
    if (this.dataContribuition.country_of_residence === 'Brazil') {
      this.currencySymbol = 'R$';
      
      this.convertDollarToRealService.convert(dataQuotation => {
        this.dataContribuition.quotation = dataQuotation.quotation;
        this.dataContribuition.quotationDate = dataQuotation.quotationdate;
        if (dataQuotation.quotation) {
          this.dataContribuition.value = (this.dataContribuition.value * dataQuotation.quotation).toFixed(2);
        }
        this.setupPayPal();
      });
    } else {
      this.setupPayPal();
    }
    this.currentStep = 2;
  }

  setupPayPal(){
    const data = this.dataContribuition;
    const currency = (data.country_of_residence === 'Brazil' && data.quotation) ? 'BRL' : 'USD';
    if ((data.country_of_residence === 'Brazil' && data.quotation) || data.country_of_residence !== 'Brazil') {
      // Clear previous button
      document.getElementById('paypal-button-container').innerHTML = '';

      const v = data.value;
      const amount = parseFloat(v.toString());
      
      // set scope in c
      let c = this;

      (<any>window).paypal.Button.render({
        env: 'production',
        locale: 'en_US',
        style: {
          size: 'responsive',
          color: 'silver',
          shape: 'rect',
          label: 'paypal',
          tagline: 'false',
          height: 50
        },
        // Pass the client ids to use to create your transaction on sandbox and production environments
        client: {
            sandbox:    'AQHnHZDJhqlSK3oxMpAP1jEej9zoZrtiV5qEOsRHw1RXzo-51Sl5z2-o0xN8GwjfjezpTPcn2oK3UksI', // from https://developer.paypal.com/developer/applications/
            production: 'AQz3_-iFSZzDaTJKl3IiZfGhp-GD1RA06L8pFdB4BKEUvpoSEbDaFha-UxTHiCYoVlp9eSZiZ0sM_jLP'  // from https://developer.paypal.com/developer/applications/
        },
        // Pass the payment details for your transaction
        // See https://developer.paypal.com/docs/api/payments/#payment_create for the expected json parameters
        payment: function(data, actions) {
          return actions.payment.create({
            transactions: [
              {
                amount: {
                  total: amount.toFixed(2),
                  currency: currency.toString()
                }
              }
            ]
          });
        },
        // Display a "Pay Now" button rather than a "Continue" button
        commit: true,
        // Pass a function to be called when the customer completes the payment
        onAuthorize: function(data, actions) {
          return actions.payment.execute().then(function(response) {
            if (response.state == 'approved') {
              c.currentStep = 3;
              const data = {
                name: c.dataContribuition.first_name + ' ' + c.dataContribuition.last_name,
                email: c.dataContribuition.email == '' ? 'não informado' : c.dataContribuition.email,
                value: amount.toFixed(2),
                status: 'success'
              };
              c.paymentService.infoContribuition(data).subscribe(res => {});
            } else {
              c.currentStep = 4;
              const data = {
                name: c.dataContribuition.first_name + ' ' + c.dataContribuition.last_name,
                email: c.dataContribuition.email == '' ? 'não informado' : c.dataContribuition.email,
                value: amount.toFixed(2),
                status: 'error'
              };
              c.paymentService.infoContribuition(data).subscribe(res => {});
            }
          });
        },
        // Pass a function to be called when the customer cancels the payment
        onCancel: function(data) {
          console.log('The payment was cancelled!');
          console.log(data);
        }
      }, '#paypal-button-container');

    }

  }
}
