export class UserModel {

    private _id: string;
    private firstname: string;
    private lastname: string;
    private email: string;
    public auth: any;
    private birth: Date;
    private country_of_residence: string;
    private address: string;
    private phoneNumber: string;
    private profession: string;
    private nationality: string;
    private receiveNews: boolean;
    public plans: Array<any>;
    public socialmedia: any;
    private created_at: any;
    private updated_at: any;
    private status: string;

    constructor (data: any = null){

        if(data != null) {
            this._id = data._id;
            this.firstname = data.firstname;
            this.lastname = data.lastname;
            this.email = data.email;
            this.country_of_residence = data.country_of_residence;
            this.address = data.address;
            this.phoneNumber = data.phoneNumber;
            this.profession = data.profession;
            this.nationality = data.nationality;
            this.receiveNews = data.receiveNews;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
            this.status = data.status;
        }
        

        this.plans = [];
        this.auth = { local: {password: ""}, facebook: { id: "", email: ""} };
    }

    getId(){ return this._id; }
    setId(value) { this._id = value; }

    getFirstName(){ return this.firstname; }
    setFirstName(value) { this.firstname = value; }

    getLastName(){ return this.lastname; }
    setLastName(value) { this.lastname = value; }

    getEmail(){ return this.email; }
    setEmail(value) { this.email = value; }

    getPassword(){ return this.auth; }
    setPassword(value) { this.auth.local = value; }

    getBirth(){ return this.birth; }
    setBirth(value) { this.birth = value; }

    getCountry(){ return this.country_of_residence; }
    setCountry(value) { this.country_of_residence = value; }

    getAddress(){ return this.address; }
    setAddress(value) { this.address = value; }

    getPhoneNumber(){ return this.phoneNumber; }
    setPhoneNumber(value) { this.phoneNumber = value; }

    getProfession(){ return this.profession; }
    setProfession(value) { this.profession = value; }

    getNationality(){ return this.nationality; }
    setNationality(value) { this.nationality = value; }

    getReceiveNews(){ return this.receiveNews; }
    setReceiveNews(value) { this.receiveNews = value; }

    getPlans(){ return this.plans; }
    setPlan(obj){ 
        if(this.plans == null){
            this.plans = [obj];
        }else
            this.plans.push(obj); 
    }
    setFirstPlan(obj){ this.plans = [obj]; }

    getSocialMedia(){ return this.socialmedia; }
    setSocialMedia(value) { this.socialmedia = value; }

    getCreatedAt(){ return this.created_at; }
    setCreatedAt(value) { this.created_at = value; }

    getUpdatedAt(){ return this.updated_at; }
    setUpdatedAt(value) { this.updated_at = value; }

    getStatus(){ return this.status; }
    setStatus(value) { this.status = value; }

}