import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn-social-icon',
  templateUrl: './btn-social-icon.component.html',
  styleUrls: ['./btn-social-icon.component.scss']
})
export class BtnSocialIconComponent {

  @Input() icon: string  = '';
  @Input() link: string = '#';

}
