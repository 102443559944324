import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  current_user = null;

  constructor(private route: ActivatedRoute, public userService: UserService) {

    
      if ( sessionStorage.getItem("current-user") != null){
        this.current_user = JSON.parse( sessionStorage.getItem("current-user") );
      }else if( localStorage.getItem("current-user") != null ){
        this.current_user = JSON.parse( localStorage.getItem("current-user") );
      }
    
  }

  ngOnInit() {

    this.route.params.subscribe( params => {

      this.userService.emailConfirmation(params.userId).subscribe((res: any) =>{
        if(res.ok){
          if ( sessionStorage.getItem("current-user") != null){

            this.current_user = JSON.parse( sessionStorage.getItem("current-user") );
            this.current_user.status = "Active";
            sessionStorage.setItem("current-user", JSON.stringify(this.current_user));

          }else if( localStorage.getItem("current-user") != null ){

            this.current_user = JSON.parse( localStorage.getItem("current-user") );
            this.current_user.status = "Active";
            localStorage.setItem("current-user", JSON.stringify(this.current_user));
          }
          
        }
      })
     
    });
  }

}
