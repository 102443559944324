import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ViewMaterialService } from "app/_services/view-material.service";
import { Location } from '@angular/common';
import { HelpersService } from 'app/_services/helpers.service';
import { UserService } from '../_services/user.service';
import { Meta } from '@angular/platform-browser';
import { BannerService } from '../_services/banner.service';
import { AuthenticateService } from 'app/_services/authenticate.service';
import alertify from 'alertifyjs';
declare var $: any;

declare var $: any;

@Component({
  selector: 'app-view-material',
  templateUrl: './view-material.component.html',
  styleUrls: ['./view-material.component.scss']
})
export class ViewMaterialComponent implements OnInit {

  /*
  * Usado para melhoar o SEO
  */
  schema: any = {
    '@context': 'http://schema.org',
    '@type': 'Case',
  }
  imageSelected: any;

  loadingClass = "";
  imgLoaded = false;
  showConfirmationOverlay = false;

  currentMl = {
    _id: '',
    mainView: {
      title: "",
      url_youtube: null,
      afterTitle : ""
    },
    descriptionView: {
      id: "",
      date: "",
      author: "",
      donatedbyurl: ""
    },
    afterTitle: [],
    images: [],
    block01:{
      icon: "",
      leftContent:{
        title: "",
        text: ""
      },
      rightContent:{
        text: []
      }
    },
    block02:{
      icon: "",
      label:"",
      title: "",
      leftContent:{
        title: "",
        contents: []
      }
    },
     block03:{
       icon: "",
       label:"",
       title: "",
       leftContent:{
        contents: []
      }
    },
     block04:{
       icon: "",
       label:"",
       title: "",
       leftContent:{
        contents: []
      }
    },
    block05:{
      icon: "",
      label:"",
      title: "",
      leftContent:{
        title: "",
        contents: []
      }
    },
    block06:{
       icon: "",
       label:"",
       title: "",
       leftContent:{
        contents: []
      }
    },
    block07:{
      leftContent: { text: ""}
    }
  };

  currentImage: any = {
    _id: "",
    site: "",
    index: "",
    description: "",
    imageProperties: [{ tooltip: "", type: "", text: ""}],
    totalViews: 0,
    zoom: ""
  };

  currentZoom = "";

  banner: any = {
    visible: false
  };

  currentUser: any;
  //Utilizado no modal de alerta de confirmação de email
  message = { cat: "", text: "", active: false };

  bannerQuery = {};

  constructor( 
    private route: ActivatedRoute, 
    private vmService: ViewMaterialService, 
    private location: Location, 
    public helpersService: HelpersService,
    private userService: UserService,
    private meta: Meta,
    private bannerService: BannerService,
    private authenticateService: AuthenticateService
  ) {
      
  
        this.route.params.subscribe( params => {
          // caso tenha o parâmetro id, está usando a rota antiga, que usa o id da imagem
          if(params.hasOwnProperty("id")){
            this.getMlImage(params.id);
    
          }else if(params.hasOwnProperty("slug")){
    
            // Caso tenha o índice da imagem na url, passa para a função o indice
            if(params.hasOwnProperty("image_index"))
              this.findMl(params.slug, params.image_index);
            else  
              this.findMl(params.slug);
          }
        });

  }

  ngOnInit() {

    this.schema.url = location.href;
    
    this.checkLogin();
  }

  checkLogin() {
    setTimeout( () => {

      let storageMode = "";

      let current_user = null;
      if ( sessionStorage.getItem("current-user") != null){
        current_user = JSON.parse( sessionStorage.getItem("current-user") );
        storageMode = "sessionStorage";
      }else if( localStorage.getItem("current-user") != null ){
        current_user = JSON.parse( localStorage.getItem("current-user") );
        storageMode = "localStorage";
      }

      if(current_user == null){

        $('#LoginModal').modal({
          backdrop: 'static',
          keyboard: false
        });
        $('#LoginModal').on('shown.bs.modal', function (e) {
          $(".modal-backdrop.in").css({ opacity: 0.9 });
        });

      }else{

        this.currentUser = current_user;

        this.userService.checkStatus(current_user._id, current_user.updated_at).subscribe((res: any) => {
          // Caso o status seja == 1
          // Os dados do usuário foram atualizados no banco de dados e devem ser atualizados na session do browser
          if(res.status == 1){
            current_user = res.user;
            if(storageMode == "localStorage"){
              localStorage.setItem("current-user", JSON.stringify(current_user));
            }else
              sessionStorage.setItem('current-user', JSON.stringify(current_user));
          }

          // if(current_user.status !== 'Active') {
          //   this.checkConfirmPopup(current_user);
          // }

        });
      }
    }, 5000);
  }

  getMlImage(_id){
    // 58baef31fdb06fdf4032c5f1
    this.vmService.getMlImage(_id).subscribe( res => {
      this.currentMl = res;
      this.getCurrentImage(_id);
    })
  }

  findMl(slug, image_index = null){
    // 58baef31fdb06fdf4032c5f1

    let query = { 'slug': slug }
    this.vmService.findMl(query).subscribe( res => {
      this.meta.updateTag({ name: 'description', content: res.mainView.title });
      if ( res.block05.leftContent.contents.length > 0 ){
        for (var i = 0; i < res.block05.leftContent.contents.length; i++) {
          this.groupTests(res.block05.leftContent.contents[i].items, function (groups) {
            res.block05.leftContent.contents[i].items = groups;
          });
        }
      }
      this.currentMl = res;

      this.bannerQuery = { 'query': {'mls': this.currentMl._id } };

      if(image_index == null){
        this.getCurrentImage(res.images[0]._id);  
        
      }else if(image_index > res.images.length){
        this.getCurrentImage(res.images[0]._id);
        alert('Image not found');
      }else
        this.getCurrentImage(res.images[image_index-1]._id);
    })
  }

  groupTests(arr, callback){
    var groups = [];
    arr.reduce(function(prev, current) {
      var index = prev.findIndex(function(j) { return j.icon == current.icon });
      if (index < 0) {
          index = prev.length;
          prev.push({
              // text: current.text,
              icon: current.icon,
              lista: []
          });
      }
      prev[index].lista.push(current);
      return prev;
    }, [])
    .map(function(item){
      groups.push(item);
        // return callback(item.lista);
    });
    return callback(groups);
  }

  getCurrentImage(_id){
     this.currentImage = this.currentMl.images.find( ( element ) => {
       return (element._id == _id);
     });
     this.currentImage.totalViews++
     this.updateImageTotalViews(this.currentImage._id);
  }

  getNextImage(){
    this.loadingClass = "visible";
    this.imgLoaded = false;
    let currentIndex = 0;
    //Percorre o array de imagens, e verifica qual a posição da imagem atual
    // Ao achar a posição da imagem atual, atribui ao objeto this.currentImage, a próxima imagem do array (i+1);
    for(let i=0; i<this.currentMl.images.length; i++){
      if(this.currentMl.images[i]._id == this.currentImage._id){

        //Se for a última imagem, volta para a primeira
        if(i+1 == this.currentMl.images.length){
          this.currentImage = this.currentMl.images[0];
        }else{
          currentIndex = i+1;
          this.currentImage = this.currentMl.images[currentIndex];
        }
          

        this.currentImage.totalViews++
        this.updateImageTotalViews(this.currentImage._id);

        //Atualiza a url com o id da imagem atual, sem recarregar a página  ////////////////////////////////////////////////
        let locationArray = location.href.split("/");
        let newLocation = "";

        if(locationArray[locationArray.length-1].length > 4  ){
          newLocation = locationArray[locationArray.length-2] + "/" + locationArray[locationArray.length-1] + "/" + (currentIndex+1);
        }else
          newLocation = locationArray[locationArray.length-3] + "/" + locationArray[locationArray.length-2] + "/" + (currentIndex+1);

        this.location.go(newLocation);
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        break; 
      }
    }
  }

  getPreviousImage(){
    this.loadingClass = "visible";
    this.imgLoaded = false;
    let currentIndex = 0;
    //Percorre o array de imagens, e verifica qual a posição da imagem atual
    // Ao achar a posição da imagem atual, atribui ao objeto this.currentImage, a próxima imagem do array (i+1);
    for(let i=0; i<this.currentMl.images.length; i++){
      if(this.currentMl.images[i]._id == this.currentImage._id){
        //Se for a primeira imagem, vai para a últim
        if(i == 0){
          currentIndex = this.currentMl.images.length-1;
          this.currentImage = this.currentMl.images[currentIndex];
        }else {
          currentIndex = i-1;
          this.currentImage = this.currentMl.images[currentIndex];
        }

        this.currentImage.totalViews++
        this.updateImageTotalViews(this.currentImage._id);

         //Atualiza a url com o id da imagem atual, sem recarregar a página  ////////////////////////////////////////////////
         let locationArray = location.href.split("/");
         let newLocation = "";
 
         if(locationArray[locationArray.length-1].length > 4  ){
           newLocation = locationArray[locationArray.length - 2] + "/" + locationArray[locationArray.length-1] + "/" + (currentIndex+1)
         }else
           newLocation = locationArray[locationArray.length-3] + "/" + locationArray[locationArray.length-2] + "/" + (currentIndex+1)
 
         this.location.go(newLocation);
         ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        break; 
      }
    }
  }

  setImage(imageObj, index){

    this.loadingClass = "visible";
    this.imgLoaded = false;

    //usa jQuery para ir para o topo
    $('html, body').animate({scrollTop : 0},800);
    imageObj.totalViews++;
    this.currentImage = imageObj;

    //Chama a rotina que incrementa o numero de visualizações da imagem atual;
    this.updateImageTotalViews(imageObj._id);


     //Atualiza a url com o id da imagem atual, sem recarregar a página  ////////////////////////////////////////////////
     let locationArray = location.href.split("/");
     let newLocation = "";

     if(locationArray[locationArray.length-1].length > 4  ){
       newLocation = locationArray[locationArray.length-2] + "/" + locationArray[locationArray.length-1] + "/" + (index+1);
     }else
       newLocation = locationArray[locationArray.length-3] + "/" + locationArray[locationArray.length-2] + "/" + (index+1);

     this.location.go(newLocation);
     ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
  }

  //Utilizado para esconder o loader da imagem
  imageLoaded(){
    this.loadingClass = "hidden";
    this.imgLoaded = true;
  }

  formatToolTipSingle(obj){

    let tooltip = "";

    obj.ancestors.forEach(element => {
      tooltip += element.name + " >> ";
    });

    tooltip += obj.name;    

    return tooltip;
  }

  formatToolTipArray(obj){

    let tooltip = "";

    obj.forEach(element => {
      tooltip += " | " + element.name;
    });

    return tooltip.substr(3);
  }

  processSearch(type, id){

    if(type == "M") type = "material";
    else if(type == "P") type = "product";
    else if(type == "Cd") type = "condition";
    else if(type == "Ap") type = "application";
    else if(type == "Cm") type = "captureMethod";
    else if(type == "Df") type = "detailFocus";

    let searchObj = {
      "filters":[
        {
        "item": type,
        "id": id
        }
      ]
    }

    localStorage.setItem("mlife-search-obj", JSON.stringify(searchObj));
    location.href = "search";
    
  }

  loadZoom(){

    this.currentZoom = this.currentImage.zoom;
  }

  updateImageTotalViews(image_id){
    this.vmService.updateTotalViews(image_id).subscribe( res =>{
    })
  }

  sendConfirmationEmail(){
    console.log("Not implemented yet");
    this.userService.emailReSendConfirmation(this.currentUser._id).subscribe((res: any) => {
      this.message.active = true;
      this.message.text = res.msg;
      setTimeout( ()=> {
        this.showConfirmationOverlay = false;
      }, 5000);

    });
  }


  /*
  * Verifies if user already viewed email confirmation popup at this session
  */
  checkConfirmPopup(current_user) {

    const confirmPopup = (sessionStorage.getItem('confirmPopup') != null ? JSON.parse(sessionStorage.getItem('confirmPopup')) : null);
    if(confirmPopup == null) {
      this.showConfirmationOverlay = true;
    }

  }

  handleLaterClick(){
    sessionStorage.setItem('confirmPopup', 'true');
    this.showConfirmationOverlay = false;
  }

  openModalAddImageToCollection(item) {
    item.descriptionView = this.currentMl.descriptionView;
    item.mainView = this.currentMl.mainView;
    if (this.authenticateService.isLogged() !== null) {
      $('#AddImageToCollectionModal').modal('show');
      this.imageSelected = item;
    } else {
      alertify.alert('You must log in to add images to collections.', function () {
          console.log("Ok");
      });
    }
  }

}
